import { object, string, InferType,array} from "yup";

export const EtablissementFilterValidationSchema = object({
    metierFilter : string().required("Ce champ est obligatoire"),
    villeFilter : string().required("Ce champ est obligatoire"),
    typeEtablissement : string(),
    disponibilitesFilter: array().of(string().defined())//.required("Ce champ est obligatoire")

});

export type EtablissementFilterValidationType = InferType<
  typeof EtablissementFilterValidationSchema
>;

import {
  Control,
  DeepMap,
  FieldError,
  FieldValues,
  Path,
  UseFormRegister,
  UseFormSetValue,
  useController,
} from "react-hook-form";
import styles from "./PhoneSection.module.scss";

type Props<FormSchemaType extends FieldValues> = {
  label: string;
  placeholder: string;
  inputId: string;
  name: Path<FormSchemaType>;
  required?: boolean;
  control:Control<any>

};
export default function PhoneSection<FormSchemaType extends FieldValues>(props: Props<FormSchemaType>) {
  const { label, placeholder, inputId, name, control } = props;

  const {field,formState} = useController<FormSchemaType>({name,control})
  const formErrors = formState.errors;
  const errorName = formErrors?.[name];
  return (
    <article className={styles.phoneSection}>
      {" "}
      <label className={styles.phoneSection__titleLabel} htmlFor={inputId}>
        {label}
      </label>
      <section className={styles.phoneSection__phoneContainer}>
        <section className={styles.phoneSection__phoneContainer__phoneCode}>
          +33
        </section>
        <input
          type="tel"
          id={inputId}
          placeholder={placeholder}
          {...field}
          has-error={errorName ? "yes" : "no"} // check the style sheet
        />
      </section>
      <label className={styles.phoneSection__errorLabel} htmlFor={inputId}>
        {formErrors?.[name]?.message?.toString()}
      </label>
    </article>
  );
}

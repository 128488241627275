import { object, string, InferType, boolean} from "yup";

const frPhoneRegex = /^[1-9]\d{8,10}$/;


export const CollaborateurFormSchema = object({

    //* Informations personnelles
    civilite : string().required("Ce champ est obligatoire"),
    nom : string().required("Ce champ est obligatoire"),
    prenom : string().required("Ce champ est obligatoire"),
    email : string().email("L'email n'est pas valide").required("Ce champ est obligatoire"),
    phone: string().matches(frPhoneRegex, "Entrez un numéro de téléphone valide")
    .required("Ce champ est obligatoire"),
    fonction : string().required("Ce champ est obligatoire"),
    direction : string().required("Ce champ est obligatoire"),

    hasDroitImage: boolean().required("Ce champ est obligatoire"),
});

// export 
export type CollaborateurFormSchemaType = InferType<
  typeof CollaborateurFormSchema
>;


import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import {
  Etablissement,
  MetierTitle,
} from "../../schemas/etablissement/etablissemen.schema";
import { Participation } from "../../schemas/collaborateur/collaborateur.schema";

export type LocalisationFilters = {
  ville: string;
  lat: number;
  lon: number;
};
interface EtablissementState {
  etablissementSearchFilter: string;
  setEtablissementSearchFilter: (newState: string) => void;

  //* new selected etablissement
  selectedEtablissement: Etablissement | null;
  setSelectedEtablissement: (newState: Etablissement | null) => void;

  //* previous selected etablissement
  oldEtablissementId: string | null;
  setOldEtablissementId: (newState: string | null) => void;
  oldEtablissement: Etablissement | null;
  setOldEtablissement: (newState: Etablissement | null) => void;

  //* new selected participation
  selectedParticipation: Participation | null;
  setSelectedParticipation: (newState: Participation | null) => void;

  //* previous selected participation
  oldParticipation: Participation | null;
  setOldParticipation: (newState: Participation | null) => void;

  //* disponibilites filter
  selectedDisponibilites: string[];
  setSelectedDisponibilites: (newState: string[]) => void;

  //* metier filter
  metierFilterValue: MetierTitle | null;
  setSelectedMetierFilterValue: (newState: MetierTitle | null) => void;

  //* localisaiton (ville) filter
  localisationFilterValue: LocalisationFilters | null;
  setSelectedLoclisationFilterValue: (newState: LocalisationFilters) => void;
}

export const useEtablissementStore = create<EtablissementState>()(
  devtools(
    persist(
      (set) => ({
        etablissementSearchFilter: "",
        setEtablissementSearchFilter: (newState) =>
          //* new selected etablissement
          set((state) => ({ etablissementSearchFilter: newState })),
        selectedEtablissement: null,
        setSelectedEtablissement: (newState) =>
          set((state) => ({ selectedEtablissement: newState })),

        //* previous selected etablissement's id
        oldEtablissementId: "",
        setOldEtablissementId: (newState) =>
          set((state) => ({ oldEtablissementId: newState })),
        oldEtablissement: null,
        setOldEtablissement: (newState) =>
          set((state) => ({ oldEtablissement: newState })),

        //* new selected participation
        selectedParticipation: null,
        setSelectedParticipation: (newState) => {
          set((state) => ({ selectedParticipation: newState }));
        },

        //* previous selected participation
        oldParticipation: null,
        setOldParticipation: (newState) => {
          set((state) => ({ oldParticipation: newState }));
        },

        //* disponibilites filter
        selectedDisponibilites: [],
        setSelectedDisponibilites: (newState) =>
          set((state) => ({ selectedDisponibilites: newState })),

        //* metier filter
        metierFilterValue: "Restauration",
        setSelectedMetierFilterValue: (newState) =>
          set((state) => ({ metierFilterValue: newState })),

        //* localisation (ville) filter
        localisationFilterValue: {
          ville: "",
          lat: 0,
          lon: 0,
        },
        setSelectedLoclisationFilterValue: (newState) =>
          set((state) => ({ localisationFilterValue: newState })),
      }),

      {
        name: "etablissement-state-storage",
      }
    )
  )
);

import { closeIcon } from "../../assets/svgs/icons/icons.svg";
import styles from "./InscriptionAnnuleeNotification.module.scss";

type Props = {
  isNotifVisible: boolean;
  setIsNotVisible: (newState: boolean) => void;
};
export default function InscriptionAnnuleeNotification(props: Props) {
  const { isNotifVisible, setIsNotVisible } = props;

  function hideNotification() {
    setIsNotVisible(false);
  }
  return (
    <>
      {isNotifVisible && (
        <article className={styles.inscriptionAnnuleeNotification}>
          <header className={styles.inscriptionAnnuleeNotification__header}>
            <h1>Votre demande a bien été prise en compte</h1>
            <button type="button" onClick={hideNotification}>
              {closeIcon}
            </button>
          </header>
          <main className={styles.inscriptionAnnuleeNotification__main}>
            <p>L'ensemble de vos données ont été supprimées.</p>
            <p>
              Vous avez jusqu'au 31 mai 2024 pour vous réinscrire, si vous le
              désirez.
            </p>
          </main>
        </article>
      )}
    </>
  );
}

import { useMsal } from "@azure/msal-react";
import { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Loader from "./components/global/Loader/Loader";
import Layout from "./components/layouts/Layout/Layout";
import { useGetCollaborateurByEmail } from "./hooks/react-query/users.queries";
import Cloture from "./pages/Cloture/Cloture";
import ErrorPage from "./pages/ErrorPage/ErrorPage";
import { FAQPage } from "./pages/FAQ";
import HomePageInscription from "./pages/HomePageInscription/HomePageInscription";
import HomePageModification from "./pages/HomePageModification/HomePageModification";
import InscriptionDemandeEnvoyeePage from "./pages/InscriptionDemandeEnvoyeePage/InscriptionDemandeEnvoyeePage";
import InscriptionPage from "./pages/InscriptionPage/InscriptionPage";
import InscriptionRecapPage from "./pages/InscriptionRecapPage/InscriptionRecapPage";
import RecapModificationpage from "./pages/RecapModificationpage/RecapModificationpage";
import RechercheEtablissementPage from "./pages/RechercheEtablissementPage/RechercheEtablissementPage";
import ResearchLoader from "./pages/RechercheLoader";
import PageNotFound from "./pages/error/PageNotFound/PageNotFound";
import { usePagesToDisplayStore } from "./stores/pagesToDisplay/pagesToDisplay.store";
import { getUserEmail } from "./utils";

export default function RouterContainer() {
  //* msal-react
  const { accounts } = useMsal();
  const { userEmail } = getUserEmail(accounts);

  //* react-query
  const { isLoading } = useGetCollaborateurByEmail(userEmail as string);

  //* zustand
  const { isModificationFlow } = usePagesToDisplayStore();
  // const { pagesToDisplay } = usePagesToDisplayStore();

  const navigate = useNavigate();

  //* Date to calculate open platform
  const openDate = new Date(process.env.REACT_APP_OPEN_DATE || "2024-05-21");
  const closeDate = new Date(process.env.REACT_APP_CLOSE_DATE || "2024-55-31");


  function zeroHour(date: Date) {
    date.setHours(0, 0, 0, 0);
    return date;
  }

  function calculateDate() {
    const dateNow = zeroHour(new Date());
    const dateStart = zeroHour(openDate);
    const dateEnd = zeroHour(closeDate);
    const resultDate = dateNow >= dateStart && dateNow <= dateEnd;
    return resultDate;
  }

  useEffect(() => {
    if (!calculateDate()) {
      navigate("/cloture");
    }
  }, []);

  if (isLoading) return <Loader />;

  return (
    <Routes>
      <Route element={<Layout />}>
        {calculateDate() ? !isModificationFlow ? (
          <Route path="">
            <Route index element={<HomePageInscription />} />
            <Route
              path="/recherche"
              element={
                <RechercheEtablissementPage
                  currentFlow="inscription"
                  onValidationLink="/inscription"
                />
              }
            />
            <Route path="/inscription" element={<InscriptionPage />} />
            <Route
              path="/inscription/recapitulatif"
              element={<InscriptionRecapPage />}
            />
            <Route
              path="/inscription/recapitulatif/recherche"
              element={
                <RechercheEtablissementPage
                  currentFlow="modification"
                  onValidationLink="/inscription/recapitulatif"
                />
              }
            />
            <Route
              path="/inscription/demande-envoyee"
              element={<InscriptionDemandeEnvoyeePage />}
            />
          </Route>
        ) : (
          <Route path="">
            <Route
              path="/inscription/recapitulatif"
              element={<InscriptionRecapPage />}
            />
            <Route
              path="/inscription/demande-envoyee"
              element={<InscriptionDemandeEnvoyeePage />}
            />
            <Route
              path="/modification/recherche"
              element={
                <RechercheEtablissementPage
                  currentFlow="modification"
                  onValidationLink="/modification/recapitulatif"
                />
              }
            />
            <Route
              path="/modification/recapitulatif"
              element={<RecapModificationpage />}
            />
            <Route path="/loading" element={<ResearchLoader />} />
          </Route>
        ) : null}
        <Route index element={<HomePageModification />} />
        <Route path="FAQ" element={<FAQPage />} />
        <Route path="error" element={<ErrorPage />} />
        <Route path="cloture" element={<Cloture />} />
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
  );
}

import signatureEmail from "../../../assets/jpg/email_signature.jpg";
import {
  ICollaborateur,
  Participation
} from "../../../schemas/collaborateur/collaborateur.schema";
import { Etablissement } from "../../../schemas/etablissement/etablissemen.schema";
import { formatDate, formatPhoneWithoutPrefix } from "../../../utils";

const dateLimitInscription = process.env.REACT_APP_DATE_LIMIT_INSCRIPTION;
const websiteUrl = process.env.REACT_APP_WEBSITE_URL;
export function generateInscriptionEmail_Collaborateur(infos: {
  collaborateurInfo: ICollaborateur;
  etablissementInfo: Etablissement;
  participation: Participation;
}) {
  const { etablissementInfo, collaborateurInfo, participation } = infos;
  return `<article>
      <p>
        Bonjour ${collaborateurInfo.prenom} ${collaborateurInfo.nom},
      </p>
      <p>
        Vous venez de vous enregistrer sur le portail “Vis ma vie” et nous vous
        en remercions.
      </p>

      <p>Vous passerez une journée de 9h30 à 17h30 dans le service suivant :</p>
      <ul>
        <li>${participation?.metierTitle}</li>
        <li>À ${etablissementInfo.name}</li>
        <li>Au ${etablissementInfo.localisation.address}</li>
        <li>
          Le ${formatDate(participation?.dateParticipation, {
    hasDayName: true,
    hasYear: true,
  })}
        </li>
        <li>
        Les contacts du ou de la DE sont les suivants : ${etablissementInfo?.information_DE?.prenom_de
    } ${etablissementInfo?.information_DE?.nom_de}, joignable au +33 ${etablissementInfo?.information_DE?.phone_de
    } ou par email à ${etablissementInfo?.information_DE?.email_de}
        </li>
      </ul>

      <p>Les contacts de votre référent Vis ma Vie sont les suivants  :</p>

      <p>NOM du référent(e) : ${etablissementInfo?.information_referent?.nom_referent || "Non renseigné"
    }</p>
      <p>Prénom du référent(e) : ${etablissementInfo?.information_referent?.prenom_referent ||
    "Non renseigné"
    }</p>
      <p>Email : ${etablissementInfo?.information_referent?.email_referent ||
    "Non renseigné"
    }</p>
      <p>Téléphone : +33 ${formatPhoneWithoutPrefix(etablissementInfo?.information_referent?.phone_referent) ||
    "Non renseigné"
    }</p>

    <p>Afin de ne pas mobiliser inutilement les équipes et les établissements, nous vous remercions d'annuler ou de modifier votre inscription en cas d'extrême nécessité. La date limite pour le faire est le ${dateLimitInscription}. Au-delà, merci de contacter directement l'établissement qui devait vous recevoir.</p>

    <p>Bien à vous.</p>

    <p>L'équipe projet “Vis ma vie”</p>
    <img
    src="${signatureEmail}"
    alt="signature_logo"
    width={100}
    height={100}
  ></img>
</article > `;
}
export function generateAnnulationEmail_Collaborateur(infos: {
  collaborateurInfo: ICollaborateur;
}) {
  const { collaborateurInfo } = infos;
  return `<article>
      <p>
        Bonjour ${collaborateurInfo.prenom} ${collaborateurInfo.nom},
      </p>

      <p>
        Vous venez d'annuler votre inscription sur le portail “Vis ma vie”.
        Votre annulation a été transmise à l'établissement concerné.
      </p>
      <p>Bien à vous.</p>

      <p>L'équipe projet “Vis ma vie”</p>
      <img
      src="${signatureEmail}"
      alt="signature_logo"
      width={100}
      height={100}
    ></img>
  </article > `;
}
export function generateModification_FullUpdate_Collaborateur(infos: {
  collaborateurInfo: ICollaborateur;
  participation: Participation;
  etablissementInfo: Etablissement;

}) {
  const { collaborateurInfo, participation, etablissementInfo } = infos;
  return (
    `<article>
      <p>Bonjour ${collaborateurInfo.prenom} ${collaborateurInfo.nom},</p>

      <p>
        Vous venez de modifier votre inscription sur le portail “Vis ma vie”.
      </p>

      <p>Vous passerez une journée de 9h30 à 17h30 dans le service suivant :</p>
      <ul>
        <li>${participation.metierTitle},</li>

        <li>À ${etablissementInfo.name} </li>

        <li>Au ${etablissementInfo.localisation.address}</li>

        <li>Le ${formatDate(participation.dateParticipation, { hasDayName: true, hasYear: true })}</li>
      </ul>

      <p>
        Les contacts du ou de la DE sont les suivants : ${etablissementInfo.information_DE.prenom_de} ${etablissementInfo.information_DE.nom_de},
        joignable au +33 ${etablissementInfo.information_DE.phone_de} ou par email à ${etablissementInfo.information_DE.email_de}
      </p>

      <p>Les contacts de votre référent Vis ma Vie sont les suivants :</p>

      <p>NOM du référent(e) : ${etablissementInfo?.information_referent?.nom_referent || "Non renseigné"}</p>
      <p>Prénom du référent(e) : ${etablissementInfo?.information_referent?.prenom_referent || "Non renseigné"}</p>
      <p>Email : ${etablissementInfo?.information_referent?.email_referent || "Non renseigné"}</p>
      <p>Téléphone : +33 ${formatPhoneWithoutPrefix(etablissementInfo?.information_referent?.phone_referent) || "Non renseigné"}</p>

      <p>
        Afin de ne pas mobiliser inutilement les équipes et les établissements,
        nous vous remercions d'annuler ou de modifier votre inscription en cas
        d'extrême nécessité. La date limite pour le faire est le ${dateLimitInscription}.
        Au-delà, merci de contacter directement l'établissement qui devait vous
        recevoir.
      </p>

      <p>Bien à vous.</p>

      <p>L'équipe projet “Vis ma vie”</p>
      <img
        src="${signatureEmail}"
        alt="signature_logo"
        width={100}
        height={100}
      ></img>
      </article > `
  );
}

import React from "react";
import { Modal } from "@mantine/core";
import { useMsal } from "@azure/msal-react";
import {
  Collaborateur,
  Direction,
  ICollaborateur,
  Participation,
} from "../../schemas/collaborateur/collaborateur.schema";
import { closeIcon } from "../../assets/svgs/icons/icons.svg";
import DropdownSection from "../inputs/DropdownSection/DropdownSection";
import {
  CollaborateurFormSchema,
  CollaborateurFormSchemaType,
} from "../../schemas/collaborateur/collaborateurForm.schema";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { useGetCivilites } from "../../hooks/react-query/dropdowns/civilites.queries";
import { useGetDirections } from "../../hooks/react-query/dropdowns/directions.queries";
import InputSection from "../inputs/InputSection/InputSection";
import PhoneSection from "../inputs/PhoneSection/PhoneSection";
import Button from "../global/Button/Button";

import InscriptionDroitImage from "../InscriptionPage/InscriptionDroitImage/InscriptionDroitImage";

import styles from "./UpdateCollaborateurPopup.module.scss";
import { getUserEmail } from "../../utils";
import { Etablissement } from "../../schemas/etablissement/etablissemen.schema";

type Props = {
  selectedCollaborateur: ICollaborateur  | null;
  setSeletecCollaborateur: (newCollaborateur: ICollaborateur | null) => void;

  isOpen: boolean;
  setIsOpen:
    | React.Dispatch<React.SetStateAction<boolean>>
    | ((newState: boolean) => void);
  cancelButtonText: string;
  confirmButtonText: string;

  onConfirm : ()=>void
};




export default function UpdateCollaborateurPopup(props: Props) {
  const {
    selectedCollaborateur,
    setSeletecCollaborateur,
    isOpen,
    setIsOpen,
    cancelButtonText,
    confirmButtonText,
    onConfirm,
  } = props;

  //* react-hook-form
  const { control, handleSubmit } = useForm<CollaborateurFormSchemaType>({
    resolver: yupResolver(CollaborateurFormSchema),
    defaultValues: {
      civilite: selectedCollaborateur?.civilite || "",
      nom: selectedCollaborateur?.nom || "",
      prenom: selectedCollaborateur?.prenom || "",
      email: selectedCollaborateur?.email || "",
      phone: selectedCollaborateur?.phone || "",
      fonction: selectedCollaborateur?.fonction || "",
      direction: selectedCollaborateur?.direction || "",
      hasDroitImage: selectedCollaborateur?.hasDroitImage===true ? true : false,
    },
  });

  

  //* react query
  const { data: civilitesData, isLoading: isLoadingCivilite } =
    useGetCivilites();
  const { data: directionsData, isLoading: isLoadingDirection } =
    useGetDirections();

    
   // * msal-react
   const { accounts } = useMsal();   
   const {  userEmail } = getUserEmail(accounts);

  function onSubmit(data: CollaborateurFormSchemaType) {
    setSeletecCollaborateur({
      _id:selectedCollaborateur?._id as string,
      civilite: data.civilite,
      direction: data.direction as Direction,
      email: data.email,
      fonction: data.fonction,
      hasDroitImage: data.hasDroitImage,
      nom: data.nom,
      phone: data.phone,
      prenom: data.prenom,
      participation: selectedCollaborateur?.participation as Participation,
      etablissement:selectedCollaborateur?.etablissement as Etablissement,
      userEmail:userEmail as string
    });
    onConfirm()
    setIsOpen(false);
  }
  function onClickClose() {
    setIsOpen(false);
  }
  return (
    <Modal
      opened={isOpen}
      onClose={onClickClose}
      centered
      withCloseButton={false}
      size="auto"
      radius={20}
      // className={styles.modal}
      styles={{


        content: {
          "&::-webkit-scrollbar": {
            display: "none"
          },
          overflowY: "hidden",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
        }
      }}
      yOffset="1vh" xOffset={0}

    >
      <article className={styles.updateCollaborateurPopup}>
        <header className={styles.updateCollaborateurPopup__header}>
          <h1>Modification de vos informations</h1>
          <button 
          type="button"
          onClick={onClickClose}
          className={styles.updateCollaborateurPopup__header__closeIconButton}>
            {closeIcon}
          </button>
        </header>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={styles.updateCollaborateurPopup__form}
        >
          <main className={styles.updateCollaborateurPopup__form__main}>
            <article
              className={
                styles.updateCollaborateurPopup__form__main__informationsPersonnelles
              }
            >
              <section
                className={
                  styles.updateCollaborateurPopup__form__main__informationsPersonnelles__civiliteContainer
                }
              >
                <section>
                  <DropdownSection<CollaborateurFormSchemaType>
                    control={control}
                    options={civilitesData?.data?.list}
                    name={"civilite"}
                    placeholder="Civilité"
                    label="Civilité"
                    inputId="collaborateur_inscription_civilite"
                    hasIcon={false}
                  />
                </section>
              </section>
              <section
                className={
                  styles.updateCollaborateurPopup__form__main__informationsPersonnelles__restOfInfosContainer
                }
              >
                <section>
                  <InputSection<CollaborateurFormSchemaType>
                    control={control}
                    label="Nom"
                    inputId="collaborateur_inscription_nom"
                    inputType="text"
                    name={"nom"}
                    placeholder="Nom"
                  />
                  <InputSection<CollaborateurFormSchemaType>
                    control={control}
                    label="Email"
                    inputId="collaborateur_inscription_email"
                    inputType="email"
                    name={"email"}
                    placeholder="Email"
                  />
                  <InputSection<CollaborateurFormSchemaType>
                    control={control}
                    label="fonction"
                    inputId="collaborateur_inscription_fonction"
                    inputType="text"
                    name={"fonction"}
                    placeholder="Indiquer la fonction que vous exercez"
                  />
                </section>
                <section>
                  <InputSection<CollaborateurFormSchemaType>
                    control={control}
                    label="Prénom"
                    inputId="collaborateur_inscription_prenom"
                    inputType="text"
                    name={"prenom"}
                    placeholder="Prénom"
                  />

                  <PhoneSection<CollaborateurFormSchemaType>
                    control={control}
                    label="Téléphone"
                    inputId="collaborateur_inscription_telephone"
                    name="phone"
                    placeholder="Téléphone"
                  />
                  <DropdownSection<CollaborateurFormSchemaType>
                    control={control}
                    options={directionsData?.data?.list}
                    name={"direction"}
                    label="Direction"
                    placeholder="Sélectionner le service dans lequel vous travaillez"
                    inputId="collaborateur_inscription_direction"
                    hasIcon={false}
                  />
                </section>
              </section>
            </article>
            <article
              className={
                styles.updateCollaborateurPopup__form__main__droitImage
              }
            >
              <InscriptionDroitImage
                checkboxInputName="hasDroitImage"
                control={control}
              />
            </article>
            
          </main>
          <footer className={styles.updateCollaborateurPopup__form__footer}>
            <Button
              text={cancelButtonText}
              buttonBackGroundColor="white"
              textColor="dark"
              onClick={onClickClose}
            />
            <Button
              text={confirmButtonText}
              buttonBackGroundColor="blue"
              textColor="white"
              type="submit"
            />
          </footer>
        </form>
      </article>
    </Modal>
  );
}

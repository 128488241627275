import styles from "./VMVDescription.module.scss";
function VMVDescription() {
  return (
    <article className={styles.vmvDescription}>
      <div
      className={styles.vmvDescription__container}
      >

      <strong>
        Du 3 au 28 juin, vivez, le temps d'une journée, le quotidien d'un
        professionnel au sein de nos cliniques et de nos maisons.
      </strong>
      <p>
        Cette initiative répond à votre souhait de tisser des liens, ou de les
        renforcer, avec les équipes opérationnelles.
      </p>
      <br></br>
      <p>
        Au cours de votre journée, vous vivrez ce qu'elles vivent, dialoguerez
        avec elles de façon privilégiée et leur apporterez votre aide.
      </p>
      <p>
        Leurs gestes, leurs expertises et leur dévouement au travail deviendront
        un peu les vôtres.
      </p>
      <br />

      <p>Choisissez votre métier et un établissement en France. </p>
      <strong>Vos collègues vous y attendent !</strong>
      </div>
    </article>
  );
}

export default VMVDescription;

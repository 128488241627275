import { useMsal } from "@azure/msal-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse, isAxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import {
  Direction,
  ICollaborateur,
  Participation
} from "../../../schemas/collaborateur/collaborateur.schema";
import { Etablissement } from "../../../schemas/etablissement/etablissemen.schema";
import collaborateurServices, {
  AddCollaborateurResponseBody,
  UpdateCollaborateurRequestBody,
} from "../../../services/collaborateur/collaborateur.services";
import mailingServiceService from "../../../services/mailing-service/mailing-service.service";
import { useCollaborteurStore } from "../../../stores/collaborateur/collaborateur.store";
import { useErrorStore } from "../../../stores/errors.store";
import { useEtablissementStore } from "../../../stores/etablissement/etablissement.store";
import { useNotificationStore } from "../../../stores/notifications/notifications.store";
import { usePagesToDisplayStore } from "../../../stores/pagesToDisplay/pagesToDisplay.store";
import { getUserEmail, getUserInfo, isEmpty } from "../../../utils";
import {
  generateAnnulationEmail_Collaborateur,
  generateInscriptionEmail_Collaborateur,
  generateModification_FullUpdate_Collaborateur,
} from "./collaborateur-email-formats";
import {
  generateAnnulationEmail_Etablissement,
  generateInscriptionEmail_Etablissement,
  generateModificationEmail_normalUpdate_Etablissement,
} from "./etablissement-email-formats";

// Add
export function useAddCollaborateur(
  etablissementId: string,
  collaborateur: ICollaborateur,
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>
) {
  const queryClient = useQueryClient();

  // * react-router-dom
  const navigate = useNavigate();

  // * zustand
  const { selectedCollaborateur } = useCollaborteurStore();
  const {
    selectedEtablissement,
    selectedParticipation,

    setOldEtablissement,
    setOldEtablissementId,
    setOldParticipation,
  } = useEtablissementStore();
  const { setIsModificationFlow } = usePagesToDisplayStore();
  const { setBackendError } = useErrorStore();

  // * User info for Mailing
  const { accounts } = useMsal();
  const { userEmail } = getUserEmail(accounts);

  return useMutation({
    mutationFn: () =>
      collaborateurServices.addCollaborateur(etablissementId, collaborateur),
    onSuccess: async (
      newEtab: AxiosResponse<AddCollaborateurResponseBody, any>
    ) => {
      const addedCollaborateur: AddCollaborateurResponseBody = newEtab.data;
      queryClient.invalidateQueries(["collaborateurs"]);

      try {
        //* email services

        // send email to collaborateur
        await mailingServiceService.sendEmail({
          email: userEmail,
          subject: "Votre inscription à l'évènement Vis ma Vie.",
          body: generateInscriptionEmail_Collaborateur({
            etablissementInfo: selectedEtablissement as Etablissement,
            collaborateurInfo: selectedCollaborateur as ICollaborateur,
            participation: selectedParticipation as Participation,
          }),
        });

        // send to etablissement DE
        await mailingServiceService.sendEmail({
          email: selectedEtablissement?.information_DE.email_de as string,
          subject:
            "Une nouvelle inscription d'un collaborateur à l'évènement Vis ma Vie.",
          body: generateInscriptionEmail_Etablissement({
            collaborateurInfo: selectedCollaborateur as ICollaborateur,
            participation: selectedParticipation as Participation,
          }),
        });

        // send to etablissement ref if available
        if (selectedEtablissement?.information_referent?.email_referent)
          await mailingServiceService.sendEmail({
            email: selectedEtablissement?.information_referent
              ?.email_referent as string,
            subject:
              "Une nouvelle inscription d'un collaborateur à l'évènement Vis ma Vie.",
            body: generateInscriptionEmail_Etablissement({
              collaborateurInfo: selectedCollaborateur as ICollaborateur,
              participation: selectedParticipation as Participation,
            }),
          });
      } catch (error: any) {
        setBackendError(error?.response?.data?.errorMessage || error);
        navigate("/error");
      }
      if (addedCollaborateur) {
        setIsLoading && setIsLoading(false);
        // setSeletecCollaborateur(addedCollaborateur);
        setOldEtablissementId(selectedEtablissement?._id as string);
        setOldEtablissement(selectedEtablissement as Etablissement);
        setOldParticipation(addedCollaborateur.participation);
      }

      navigate("/inscription/demande-envoyee");
      setIsLoading && setIsLoading(false);
      setIsModificationFlow(true);

      //   setPagesToDisplay(["demande-envoyee", "recapitulatif"]);
    },

    onError: (error: any) => {
      if (
        isAxiosError(error) &&
        error.response &&
        error?.response?.status === 409
      ) {
        setBackendError(error.response.data.errorMessage);
        navigate("/error");
      }

      setBackendError(error.response.data.errorMessage || error);
      navigate("/error");
      setIsLoading && setIsLoading(false);
    },
  });
}

// Update
export function useUpdateCollaborateur(params: {
  newEtablissementId: string;
  collaborateurId: string;
  collaborateurBody: UpdateCollaborateurRequestBody;
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const {
    newEtablissementId,
    collaborateurId,
    collaborateurBody,
    setIsLoading,
  } = params;
  const queryClient = useQueryClient();

  // * react-router-dom
  const navigate = useNavigate();

  // * zustand
  const { selectedCollaborateur, setSeletecCollaborateur } =
    useCollaborteurStore();

  const {
    selectedEtablissement,
    selectedParticipation,
    oldParticipation,

    setOldEtablissementId,
    setOldEtablissement,
    setOldParticipation,
  } = useEtablissementStore();

  const { setBackendError } = useErrorStore();

  async function onSuccessFunction__fullUpdate(
    data: AxiosResponse<ICollaborateur, any>,
    variables: void,
    context: unknown
  ) {
    const updatedCollaborateur: ICollaborateur = data.data;
    queryClient.invalidateQueries(["collaborateurs"]);

    try {
      // send 'Modification' email to collaborateur
      await mailingServiceService.sendEmail({
        email: selectedEtablissement?.information_DE?.email_de as string,
        subject: "Votre modification à l'évènement Vis ma Vie.",
        body: generateModification_FullUpdate_Collaborateur({
          collaborateurInfo: selectedCollaborateur as ICollaborateur,
          etablissementInfo: selectedEtablissement as Etablissement,
          participation: selectedParticipation as Participation,
        }),
      });
      // send annulation email to old etablissement

      // etablissement DE
      await mailingServiceService.sendEmail({
        email: selectedEtablissement?.information_DE?.email_de as string,
        subject:
          "Un collaborateur vient d'annuler sa participation à l'évènement Vis ma Vie.",
        body: generateAnnulationEmail_Etablissement({
          collaborateurInfo: selectedCollaborateur as ICollaborateur,
          participation: oldParticipation as Participation,
        }),
      });
      // etablissement ref if available
      if (selectedEtablissement?.information_referent?.email_referent)
        await mailingServiceService.sendEmail({
          email: selectedEtablissement?.information_referent
            ?.email_referent as string,
          subject:
            "Un collaborateur vient d'annuler sa participation à l'évènement Vis ma Vie.",
          body: generateAnnulationEmail_Etablissement({
            collaborateurInfo: selectedCollaborateur as ICollaborateur,
            participation: oldParticipation as Participation,
          }),
        });

      // send inscription email to new etablissement
      // etablissement DE
      await mailingServiceService.sendEmail({
        email: selectedEtablissement?.information_DE.email_de as string,
        subject:
          "Une nouvelle inscription d'un collaborateur à l'évènement Vis ma Vie.",
        body: generateInscriptionEmail_Etablissement({
          collaborateurInfo: selectedCollaborateur as ICollaborateur,
          participation: selectedParticipation as Participation,
        }),
      });

      // etablissement ref if available
      if (selectedEtablissement?.information_referent?.email_referent)
        await mailingServiceService.sendEmail({
          email: selectedEtablissement?.information_referent
            ?.email_referent as string,
          subject:
            "Une nouvelle inscription d'un collaborateur à l'évènement Vis ma Vie.",
          body: generateInscriptionEmail_Etablissement({
            collaborateurInfo: selectedCollaborateur as ICollaborateur,
            participation: selectedParticipation as Participation,
          }),
        });
    } catch (error) {
      setIsLoading && setIsLoading(false);

      setBackendError(error);
    }

    setOldParticipation(selectedParticipation);
    setOldEtablissement(selectedEtablissement);
    setOldEtablissementId(newEtablissementId);

    // setSeletecCollaborateur(updatedCollaborateur);
    setIsLoading && setIsLoading(false);
    navigate("/");
  }
  async function onSuccessFunction(
    data: AxiosResponse<ICollaborateur, any>,
    variables: void,
    context: unknown
  ) {
    const updatedCollaborateur: ICollaborateur = data.data;
    queryClient.invalidateQueries(["collaborateurs"]);

    try {
      // send 'Modification' email to collaborateur
      await mailingServiceService.sendEmail({
        email: selectedEtablissement?.information_DE?.email_de as string,
        subject:
          "Une nouvelle inscription d'un collaborateur à l'évènement Vis ma Vie.",
        body: generateModificationEmail_normalUpdate_Etablissement({
          collaborateurInfo: selectedCollaborateur as ICollaborateur,
          participation: selectedParticipation as Participation,
        }),
      });
      setIsLoading && setIsLoading(false);
    } catch (error) {
      setIsLoading && setIsLoading(false);

      setBackendError(error);
    }

    setIsLoading && setIsLoading(false);
    navigate("/");
  }
  // in case if the user changes the participation
  let isFullUpdate = false;
  let updateCollaborateurRequestBody: UpdateCollaborateurRequestBody;
  if (
    collaborateurBody.newParticipation &&
    collaborateurBody.oldParticipation &&
    collaborateurBody.oldEtablissementId
  ) {
    updateCollaborateurRequestBody = {
      hasDroitImage: collaborateurBody.hasDroitImage as boolean,
      direction: collaborateurBody.direction as Direction,
      fonction: collaborateurBody.fonction,
      email: collaborateurBody.email,
      phone: collaborateurBody.phone,
      prenom: collaborateurBody.prenom,
      nom: collaborateurBody.nom,
      civilite: collaborateurBody.civilite,
      userEmail: collaborateurBody.userEmail,

      newParticipation: collaborateurBody.newParticipation as Participation,
      oldParticipation: collaborateurBody.oldParticipation as Participation,
      oldEtablissementId: collaborateurBody.oldEtablissementId as string,
    };
    isFullUpdate = true;
  } else {
    // in case the user only changes the collaborateur's normal info

    updateCollaborateurRequestBody = {
      hasDroitImage: collaborateurBody.hasDroitImage as boolean,
      direction: collaborateurBody.direction as Direction,
      fonction: collaborateurBody.fonction,
      email: collaborateurBody.email,
      phone: collaborateurBody.phone,
      prenom: collaborateurBody.prenom,
      nom: collaborateurBody.nom,
      civilite: collaborateurBody.civilite,
      userEmail: collaborateurBody.userEmail,
    };
    isFullUpdate = false;
  }

  return useMutation({
    mutationFn: () =>
      collaborateurServices.updateCollaborateur(
        {
          collaborateurId: collaborateurId,
          etablissementId: newEtablissementId,
        },
        updateCollaborateurRequestBody
      ),
    onSuccess: isFullUpdate ? onSuccessFunction__fullUpdate : onSuccessFunction,
    onError: (error) => {
      setIsLoading && setIsLoading(false);
      setBackendError(error);
    },
  });
}
// Delete
export function useDeleteCollaborateur(
  collaborateurId: string,
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>
) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  // * zustand
  const { selectedCollaborateur, setSeletecCollaborateur } =
    useCollaborteurStore();
  const {
    selectedEtablissement,
    setSelectedEtablissement,
    setOldEtablissement,
    setOldEtablissementId,

    selectedParticipation,
    setSelectedParticipation,
    setOldParticipation,

    setSelectedDisponibilites,
    setSelectedLoclisationFilterValue,
    setEtablissementSearchFilter,
  } = useEtablissementStore();

  const { setIsModificationFlow } = usePagesToDisplayStore();
  const { setBackendError } = useErrorStore();

  const { setShowCancelInscriptionNotification } = useNotificationStore();

  // * User info for Mailing
  const { accounts } = useMsal();
  const { userEmail } = getUserInfo(accounts);

  return useMutation({
    mutationFn: () =>
      collaborateurServices.deleteCollaborateur(collaborateurId),
    onSuccess: async () => {
      try {
        queryClient.invalidateQueries(["collaborateurs"]);

        //* email services

        // send email to collaborateur
        await mailingServiceService.sendEmail({
          email: userEmail,
          subject:
            "Votre annulation de participation à l'évènement Vis ma Vie.",
          body: generateAnnulationEmail_Collaborateur({
            collaborateurInfo: selectedCollaborateur as ICollaborateur,
          }),
        });
        // send email to etablissement DE
        await mailingServiceService.sendEmail({
          email: selectedEtablissement?.information_DE.email_de as string,
          subject:
            "Un collaborateur vient d'annuler sa participation à l'évènement Vis ma Vie.",
          body: generateAnnulationEmail_Etablissement({
            collaborateurInfo: selectedCollaborateur as ICollaborateur,
            participation: selectedParticipation as Participation,
          }),
        });

        // send email to etablissement ref if available
        if (selectedEtablissement?.information_referent?.email_referent)
          await mailingServiceService.sendEmail({
            email: selectedEtablissement?.information_referent
              ?.email_referent as string,
            subject:
              "Un collaborateur vient d'annuler sa participation à l'évènement Vis ma Vie.",
            body: generateAnnulationEmail_Etablissement({
              collaborateurInfo: selectedCollaborateur as ICollaborateur,
              participation: selectedParticipation as Participation,
            }),
          });
      } catch (error: unknown) {
        if (!(typeof error === "object" && !isEmpty(error))) {
          setBackendError(error);
          // navigate("/error");
        }
      }
      //* cleanup
      // cleanup collaborateur state
      setSeletecCollaborateur(null);

      // cleanup etablissement state
      setSelectedEtablissement(null);
      setOldEtablissementId(null);
      setOldEtablissement(null);

      // cleanup participation state
      setSelectedParticipation(null);
      setOldParticipation(null);

      // cleanup filter values for recherche etablissement
      setSelectedDisponibilites([]);
      setEtablissementSearchFilter("");
      setSelectedLoclisationFilterValue({
        ville: "",
        lat: 0,
        lon: 0,
      });

      setIsLoading && setIsLoading(false);
      setIsModificationFlow(false);

      //* send cancel participation notification
      setShowCancelInscriptionNotification(true);
      // Reload page
      navigate(0); // same as window.location.reload()
    },
    onError: (error) => {
      setBackendError(error);

      setIsLoading && setIsLoading(false);
    },
  });
}

import axiosClient from "../../libs/axios";

async function getTypeEtablissements() {
  return axiosClient.get(`/drop-downs/type-etablissement`);
}

const typeEtablissementsServices = {
  getTypeEtablissements,
};

export default typeEtablissementsServices;

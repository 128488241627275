import { useState, useEffect, useRef } from "react";
import {
  DeepMap,
  FieldError,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetError,
} from "react-hook-form";
import mapsSearchServices from "../../../services/maps-search/maps-search.service";

import styles from "./MapsSearchDropdownFilter.module.scss";
import {
  LocalisationFilters,
  useEtablissementStore,
} from "../../../stores/etablissement/etablissement.store";
import { LoctionIcon } from "../../../assets/svgs/location";

type Props = {
  placeholder: string;
  inputId: string;
  name: any;
  register: UseFormRegister<any>;
  formErrors: DeepMap<any, FieldError>;

  setError: UseFormSetError<any>;
  clearErrors: UseFormClearErrors<any>;

  localisation: LocalisationFilters;
  setLocalisation: React.Dispatch<React.SetStateAction<LocalisationFilters>>;
  isUsingInHomePage?: boolean;
};
export type ILocalisation = {
  id: string;
  address: string;
  region: string;
  municipality: string;
  countrySecondarySubdivision: string;
  latitude: number;
  longitude: number;
};
export default function MapsSearchDropdownFilter(props: Props) {
  const {
    localisation,
    setLocalisation,
    placeholder,
    inputId,
    name,
    register,
    formErrors,
    setError,
    clearErrors,
    isUsingInHomePage,
  } = props;

  //* zustand
  const { setSelectedLoclisationFilterValue } = useEtablissementStore();
  //* useState
  const [searchTerm, setSearchTerm] = useState(localisation.ville || "");
  const [options, setOptions] = useState<ILocalisation[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [areOptionsVisible, setAreOptionsVisible] = useState<boolean>(false);
  const [isOptionSelected, setIsOptionSelected] = useState(false);

  //* useRef
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (searchTerm === "") {
      setSelectedLoclisationFilterValue({
        ville: "",
        lat: 0,
        lon: 0,
      });
    }
    async function fetchData() {
      try {
        setIsLoading(true);
        const response = await mapsSearchServices.getMapSearchResults(
          searchTerm
        );
        setOptions(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    }

    if (searchTerm) {
      fetchData();
    } else {
      setOptions([]);
    }
    if (localisation.ville) {
      clearErrors(name);
    }
    function handleClickOutside(event: MouseEvent) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        setAreOptionsVisible(false);
      }
    }

    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [searchTerm, name]);

  const uniqueOptions = Array.from(
    new Set(options.map((option) => option.municipality))
  );

  return (
    <section ref={wrapperRef} className={styles.mapsSearchSection}>
      <article className={styles.mapsSearchSection__search}>
        <div
          className={styles.icon_wrapper}
          style={{
            top: isUsingInHomePage ? "40%" : "27%",
          }}
        >
          {LoctionIcon}
        </div>
        <input
          className={styles.mapsSearchSection__search__input}
          style={{
            boxShadow: isUsingInHomePage
              ? "inset -1px 1px 5px rgba(0, 0, 0, 0.2)"
              : "none",
            marginTop: isUsingInHomePage ? "8px" : "0",
          }}
          type="search"
          id={inputId}
          value={searchTerm}
          placeholder={placeholder}
          autoComplete="off"
          {...register(name)}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            setAreOptionsVisible(true);
            setIsOptionSelected(false); // Reset the option selected state
            if (!searchTerm && !localisation.ville) {
              setError(name, {
                type: "required",
                message: "Ce champ est obligatoire",
              });
            }
            if (!localisation.ville) {
              setError(name, {
                type: "required",
                message: "Ce champ est obligatoire",
              });
            }
          }}
          has-error={formErrors?.[name]?.message ? "yes" : "no"} // check the style sheet
        />

        {areOptionsVisible && (
          <section className={styles.mapsSearchSection__search__options}>
            {isLoading ? (
              <option value={""}>Chargement...</option>
            ) : (
              <>
                <option value={placeholder} disabled hidden>
                  {placeholder}
                </option>
                {Array.isArray(options) && options.length === 0 ? (
                  <option value={placeholder}>Aucune ville trouvée</option>
                ) : (
                  uniqueOptions.map((municipality) => (
                    <option
                      onClick={(e) => {
                        setSearchTerm(e.currentTarget.value);
                        setIsOptionSelected(true); // Option is selected
                        const selectedOption = options.find(
                          (option) => option.municipality === municipality
                        );
                        if (selectedOption) {
                          setLocalisation({
                            ville: selectedOption.municipality,
                            lat: selectedOption.latitude,
                            lon: selectedOption.longitude,
                          });
                          setSelectedLoclisationFilterValue({
                            ville: selectedOption.municipality,
                            lat: selectedOption.latitude,
                            lon: selectedOption.longitude,
                          });
                        }
                        setOptions([]);
                        setAreOptionsVisible(false);
                      }}
                      key={municipality}
                      value={municipality}
                    >
                      {municipality}
                    </option>
                  ))
                )}
              </>
            )}
          </section>
        )}
      </article>
      {formErrors?.[name]?.message && !isOptionSelected && (
        <label
          className={styles.mapsSearchSection__errorLabel}
          htmlFor={inputId}
        >
          {formErrors?.[name]?.message}
        </label>
      )}
    </section>
  );
}

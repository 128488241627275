import React from 'react'
import styles from "./VMVResearch.module.scss"
import GradientButton from '../../global/GradientButton/GradientButton'
import { useNavigate } from 'react-router-dom'

const VMVResearch = () => {
  const navigate = useNavigate()

  const handleGoToResearchPage = () => {
    navigate("/recherche")
  }

  return (
    <article className={styles.vmv_research_container}>
        <div>
            <p>
                Vous voulez participez au Vis ma vie 2024 ? {<br/>}
                Trouvez un établissement au pus proche de chez vous et inscrivez-vous dès maintenant !
            </p>
            <GradientButton onClick={handleGoToResearchPage} text="Trouver un établissement" />
        </div>
    </article>
  )
}

export default VMVResearch
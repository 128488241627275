import styles from "./Button.module.scss";

export type BackgroundColor = "white" | "blue" | "red" | "linear-blue";

export type TextColor = "white" | "dark" | "blue";

type BorderColor = "dark" | "blue" | "white" | "red" | "transparent";

type Props = {
  text: string;
  buttonBackGroundColor: BackgroundColor;
  textColor: TextColor;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  icon?: JSX.Element;
  rightIcon?: JSX.Element;
  type?: "button" | "submit" | "reset" | undefined;
  customBorderColor?: BorderColor;
};

export default function Button(props: Props) {
  const {
    text,
    buttonBackGroundColor,
    textColor,
    onClick,
    icon,
    rightIcon,
    type = "button",
    customBorderColor,
  } = props;

  return (
    <button
      type={type}
      data-background-color={buttonBackGroundColor}
      data-text-color={textColor}
      data-border-color={customBorderColor}
      className={styles.button}
      onClick={onClick}
    >
      {icon && (
        <>
          {icon}
          {` `} {/* This is the space between the icon and the text */}
        </>
      )}
      {text}
      {rightIcon && (
        <>
          {` `} {/* This is the space between the icon and the text */}
          {rightIcon}
        </>
      )}
    </button>
  );
}

import axiosClient from "../../libs/axios";

async function getCivilite(){
    return axiosClient.get('/drop-downs/civilite')
}

const civilitesServices = {
    getCivilite
}

export default civilitesServices;
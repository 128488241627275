import React from 'react'

import styles from './VMVMetier.module.scss'
import Restauration from '../../../assets/png/metier/restauration.png'
import Maintenance from '../../../assets/png/metier/maintenance.png'
import Animation from '../../../assets/png/metier/animation.png'
import Admin from '../../../assets/png/metier/admin.png'
import Motel from '../../../assets/png/metier/motel.png'
import Bg from "../../../assets/png/metier/bg.png"

const VMVMetier = () => {
  return (
    <article className={styles.metier_container}>
        <img className={styles.bg} src={Bg} alt="bg"   / >
        <h1>Découvrez nos métiers pas comme les autres</h1>
        <section>
            <div>
                <div className={styles.headers_container}>
                    <h2>Restauration</h2>
                    <h3>Cuisinier(ère)</h3>
                </div>
                <div className={styles.seprator}></div>
                <p>
                    Prêt(e)s à tester votre créativité <br />
                    culinaire avec nos chefs ? <br />
                    Retroussez vos manches et <br />
                    épluchez, émincez, assaisonnez et <br />
                    dressez et les plats pour régaler <br />
                    les papilles de nos résidents et <br />
                    patients.
                </p>
            </div>
            <img src={Restauration} alt='restauration' />
        </section>
        <section>
            <div>
                <div className={styles.headers_container}>
                    <h2>Maintenance</h2>
                    <h3>Responsable Technique ou Technicien</h3>
                </div>
                
                <div className={styles.seprator}></div>
                <p>
                    Prêt(e)s à arpenter les moindres <br />
                    recoins d’un établissement ? <br />
                    Accompagnez nos Responsables <br />
                    Techniques et développez votre <br />
                    habileté et votre dextérité au <br />
                    service de tous et du bon <br />
                    fonctionnement de nos sites.
                </p>
            </div>
            <img src={Maintenance} alt='Maintenance' />
        </section>
        <section>
            <div>
                <div className={styles.headers_container}>
                    <h2>Animation</h2>
                    <h3>Animateur(trice)</h3>
                </div>
                <div className={styles.seprator}></div>
                <p>
                    Prêt(e)s à réviser vos classiques ?<br />
                    Le temps d’une journée, devenez<br />
                    Animateur en établissement et<br />
                    divertissez nos résidents autour<br />
                    d’activités artistiques, ludiques et<br />
                    sportives.
                </p>
            </div>
            <img src={Animation} alt='Animation' />
        </section>
        <section>
            <div>
                <div className={styles.headers_container}>
                    <h2>Accueil et administration</h2>
                    <h3>Agent d’accueil</h3>
                </div>
                <div className={styles.seprator}></div>
                <p>
                    Prêt(e)s à accueillir nos patients<br />
                    et leurs proches ?<br />
                    Découvrez avec nous ce métier<br />
                    polyvalent et soyez réactifs et<br />
                    dynamiques. Votre sourire et<br />
                    votre bonne humeur seront de<br />
                    très bons atouts..
                </p>
            </div>
            <img src={Admin} alt='Admin' />
        </section>
        <section>
            <div>
                <div className={styles.headers_container}>
                    <h2>Hôtellerie</h2>
                    <h3>Agent de Service Hôtelier (ASH),<br />Serveur(euse)</h3>
                </div>
                
                <div className={styles.seprator}></div>
                <p>
                    Prêt(e)s à incarner l’excellence<br />
                    hôtelière ?<br />
                    Confort des patients et des<br />
                    résidents, propreté des locaux,<br />
                    qualité de service en restauration<br />
                    et lingerie... Venez relever à nos<br />
                    côtés tous ces défis qui rendent<br />
                    notre métier passionnant.
                </p>
            </div>
            <img src={Motel} alt='Motel' />
        </section>
    </article>
  )
}

export default VMVMetier
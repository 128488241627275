import { useQuery } from "@tanstack/react-query";
import { useEtablissementStore } from "../../stores/etablissement/etablissement.store";
// import { usePagesToDisplayStore } from "../../stores/pages-to-display/pages-to-display.store";
import usersServices from "../../services/users.service";
import { usePagesToDisplayStore } from "../../stores/pagesToDisplay/pagesToDisplay.store";
import { useCollaborteurStore } from "../../stores/collaborateur/collaborateur.store";
import { Etablissement } from "../../schemas/etablissement/etablissemen.schema";

// Get by email
export function useGetCollaborateurByEmail(email: string) {
  //* zustand
  const { setSeletecCollaborateur } = useCollaborteurStore();
  const {  setSelectedEtablissement,setSelectedParticipation, setOldEtablissementId,setOldEtablissement, setOldParticipation } =
    useEtablissementStore();
  const { setIsModificationFlow } = usePagesToDisplayStore();

  return useQuery({
    queryKey: ["collaborateurs", email],
    queryFn: () => usersServices.getCollaborateurByEmail(email),
    refetchInterval: 0,
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess: (fetchedCollaborateur) => {
      const collaborateur = fetchedCollaborateur?.data;
      

      if (collaborateur) {
        // the request can be successfull even if it returns an empty object
        setSeletecCollaborateur(collaborateur);
        setSelectedEtablissement(collaborateur.etablissement as Etablissement);
        setSelectedParticipation(collaborateur.participation)
        setOldEtablissementId(collaborateur?.etablissement?._id as string);
        setOldEtablissement(collaborateur.etablissement as Etablissement)
        setOldParticipation(collaborateur.participation);
        setIsModificationFlow(true);
      } else {
        setIsModificationFlow(false);
        return;
      }
    },
    onError: () => {
      setIsModificationFlow(false);
    },
  });
}

import ehpadLogo from "../../assets/png/EHPAD_logo.png";
import cliniqueLogo from "../../assets/png/clinique_logo.png";
import { noDayAvailableIcon } from "../../assets/svgs/icons/icons.svg";
import { Participation } from "../../schemas/collaborateur/collaborateur.schema";
import {
  Disponibilite,
  Etablissement,
  Localisation,
} from "../../schemas/etablissement/etablissemen.schema";
import { useEtablissementStore } from "../../stores/etablissement/etablissement.store";
import { areDatesEqual, formatDate } from "../../utils";

import styles from "./EtablissementCard.module.scss";

type Props = {
  etablissement: Etablissement;
  setIsAddCollaborateurPopupOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
export default function EtablissementCard(props: Props) {
  const { etablissement, setIsAddCollaborateurPopupOpen } = props;
  const { setSelectedEtablissement, setSelectedParticipation } =
    useEtablissementStore();
  const dayNames = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi"];

  const { selectedParticipation } = useEtablissementStore();
  const { selectedDisponibilites } = useEtablissementStore();
  function onClickDayButton(
    etablissement: Etablissement,
    participation: Participation
  ) {
    setSelectedEtablissement(etablissement);
    setSelectedParticipation(participation);
  }

  const handleAddressClick = (event: any, localisation: Localisation) => {
    event.preventDefault();

    const encodedAddress = encodeURIComponent(localisation.address);
    const googleMapsUrl = `https://www.google.com/maps/place/${encodedAddress}/@${localisation.latitude},${localisation.longitude},17z`;

    window.open(googleMapsUrl, "_blank");
  };
  return (
    <article className={styles.etablissementCard}>
      <section className={styles.etablissementCard__logoSection}>
        <section
          className={styles.etablissementCard__logoSection__logo}
          style={{
            backgroundImage: `url(${etablissement?.type === "EHPAD" ? ehpadLogo : cliniqueLogo
              })`,
          }}
        ></section>
      </section>
      <section className={styles.etablissementCard__etablissementInfos}>
        <header
          className={styles.etablissementCard__etablissementInfos__header}
        >
          <h1>{etablissement?.name || ""}</h1>
          <h2>{etablissement?.type || ""} </h2>
        </header>
        <main className={styles.etablissementCard__etablissementInfos__main}>
          <address>
            {etablissement?.localisation?.address || ""}
          </address>
          <a
            title={etablissement?.localisation?.address || ""}
            href="#"
            onClick={(event) =>
              handleAddressClick(event, etablissement?.localisation)
            }
          >
            Voir sur la carte
          </a>
        </main>
      </section>
      <section
        className={styles.etablissementCard__etablissementDisponibilites}
      >
        <header
          className={
            styles.etablissementCard__etablissementDisponibilites__header
          }
        >
          <h1>Disponibilités</h1>
        </header>
        <main
          className={
            styles.etablissementCard__etablissementDisponibilites__main
          }
        >
          <section
            className={
              styles.etablissementCard__etablissementDisponibilites__main__dayNames
            }
          >
            {dayNames.map((day: string, index) => (
              <p key={index + day + "dayName_etablissementCard"}>{day}</p>
            ))}
          </section>
          <section
            className={
              styles.etablissementCard__etablissementDisponibilites__premiereSemaine
            }
          >
            <SemaineList
              semaine={etablissement?.metiers[0]?.premiere_semaine}
              etablissement={etablissement}
              selectedDisponibilites={selectedDisponibilites}
              selectedParticipation={selectedParticipation}
              setSelectedEtablissement={setSelectedEtablissement}
              setSelectedParticipation={setSelectedParticipation}
              setIsAddCollaborateurPopupOpen={setIsAddCollaborateurPopupOpen}
            />
          </section>
          <section
            className={
              styles.etablissementCard__etablissementDisponibilites__deuxiemeSemaine
            }
          >
            <SemaineList
              semaine={etablissement?.metiers[0]?.deuxieme_semaine}
              etablissement={etablissement}
              selectedDisponibilites={selectedDisponibilites}
              selectedParticipation={selectedParticipation}
              setSelectedEtablissement={setSelectedEtablissement}
              setSelectedParticipation={setSelectedParticipation}
              setIsAddCollaborateurPopupOpen={setIsAddCollaborateurPopupOpen}
            />
          </section>
          <section
            className={
              styles.etablissementCard__etablissementDisponibilites__troiseimeSemaine
            }
          >

            <SemaineList
              semaine={etablissement?.metiers[0]?.troisieme_semaine}
              etablissement={etablissement}
              selectedDisponibilites={selectedDisponibilites}
              selectedParticipation={selectedParticipation}
              setSelectedEtablissement={setSelectedEtablissement}
              setSelectedParticipation={setSelectedParticipation}
              setIsAddCollaborateurPopupOpen={setIsAddCollaborateurPopupOpen}
            />
          </section>
          <section
            className={
              styles.etablissementCard__etablissementDisponibilites__quatriemeSemaine
            }
          >

            <SemaineList
              semaine={etablissement?.metiers[0]?.quatrieme_semaine}
              etablissement={etablissement}
              selectedDisponibilites={selectedDisponibilites}
              selectedParticipation={selectedParticipation}
              setSelectedEtablissement={setSelectedEtablissement}
              setSelectedParticipation={setSelectedParticipation}
              setIsAddCollaborateurPopupOpen={setIsAddCollaborateurPopupOpen}
            />
          </section>
        </main>
      </section >
    </article >
  );
}

type SemaineListProps = {
  semaine: Disponibilite[] | null;
  selectedDisponibilites: string[];
  selectedParticipation: Participation | null;
  etablissement: Etablissement;

  setSelectedEtablissement: (newState: Etablissement) => void;
  setSelectedParticipation: (newState: Participation) => void;

  setIsAddCollaborateurPopupOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
function SemaineList(props: SemaineListProps) {
  const {
    semaine,
    selectedDisponibilites,
    etablissement,
    selectedParticipation,
    setSelectedEtablissement,
    setSelectedParticipation,

    setIsAddCollaborateurPopupOpen,
  } = props;

  function onClickDayButton(
    etablissement: Etablissement,
    participation: Participation
  ) {
    setIsAddCollaborateurPopupOpen(true);
    setSelectedEtablissement(etablissement);
    setSelectedParticipation(participation);
  }
  return (
    <>
      {semaine &&
        semaine.map((disponibilite: Disponibilite, index) => {
          const date = new Date(disponibilite.date);
          const year = date.getFullYear();
          const month = (date.getMonth() + 1).toString().padStart(2, "0"); // add leading zero if needed
          const day = date.getDate().toString().padStart(2, "0"); // add leading zero if needed

          const dateString = `${year}-${month}-${day}`;
          if (
            disponibilite.nombre_professionnel_disponible === 0 ||
            disponibilite?.nombre_participation >=
            disponibilite.nombre_professionnel_disponible
          ) {
            return (
              <section
                className={
                  styles.etablissementDisponibilites__main__noDayDisponible
                }
                key={`jourbox_card_${index}`}
              >
                {noDayAvailableIcon}
              </section>
            );
          } else if (
            !selectedDisponibilites.includes(dateString) &&
            selectedDisponibilites.length !== 0
          )
            // return (
            //   <button
            //     type="button"
            //     key={
            //       index +
            //       etablissement.name +
            //       "disponibilite_etablissementCard__premiereSemaine"
            //     }
            //     disabled
            //     button-type={"disabled"}
            //   >
            //     {formatDate(disponibilite.date, {hasDayName:true})}
            //   </button>
            // );
            return (
              <section
                className={
                  styles.etablissementDisponibilites__main__noDayDisponible
                }
                key={`jourbox_card_${index}`}
              >
                {noDayAvailableIcon}
              </section>
            );
          else if (disponibilite.nombre_professionnel_disponible > 0)
            return (
              <button
                type="button"
                key={
                  index +
                  etablissement.name +
                  "disponibilite_etablissementCard__premiereSemaine"
                }
                onClick={() =>
                  onClickDayButton(etablissement, {
                    dateParticipation: new Date(disponibilite.date),
                    metierTitle: etablissement?.metiers[0]?.title,
                    etablissementName: etablissement?.name,
                  })
                }
                button-type={
                  selectedParticipation?.etablissementName ===
                    etablissement.name &&
                    areDatesEqual(
                      selectedParticipation.dateParticipation,
                      disponibilite?.date
                    )
                    ? "selected"
                    : "default"
                }
              >
                {formatDate(disponibilite.date, { hasDayName: true })}
              </button>
            );
        })}
    </>
  );
}

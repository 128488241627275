import React from "react";

import { Pagination } from "@mantine/core";

import styles from "./PaginationFooter.module.scss";

type Props = {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;

  pageLimit: number;
  setPageLimit: React.Dispatch<React.SetStateAction<number>>;

  totalSize: number;
};

export default function PaginationFooter(props: Props) {
  const { totalSize, pageLimit, setPageLimit, page, setPage } = props;
  const totalPages = Math.ceil(totalSize / pageLimit);
  if (page > totalPages) setPage(1);
  return (
    <nav className={styles.paginationFooter}>
      {totalSize>0 && (
        <>
          <article className={styles.paginationFooter__pagination}>
            <Pagination total={totalPages} value={page} onChange={setPage} />
          </article>
          <article
            className={styles.paginationFooter__pageLimitSelectContainer}
          >
            <label htmlFor="pageLimitSelect">Afficher</label>
            <select
              id="pageLimitSelect"
              value={pageLimit}
              className={styles.pageSizeSelect}
              onChange={(e) => setPageLimit(+e.currentTarget.value)}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
            </select>
            <span className={styles.par_page}>par page</span>
          </article>
        </>
      )}
    </nav>
  );
}

import { leftArrowIcon } from "../../../assets/svgs/icons/icons.svg";
import FooterBackGroundImage from "../../../assets/png/error-page-decoration.png";

import { Link } from "react-router-dom";

import styles from "./PageNotFound.module.scss";

export default function PageNotFound() {
  return (
    <article className={styles.pageNotFound}>
      <main className={styles.pageNotFound__box}>
        <h1>Page non trouvée</h1>
        <p>
          Nous n'arrivons pas à trouver la page que vous recherchez. Il se peut
          que l'URL saisie soit mal orthographié ou que la page que vous
          recherchez n'existe plus..
        </p>
        <Link to="/">
          {leftArrowIcon} Revenir à la page d'accueil
        </Link>
      </main>
      <footer
        className={styles.pageNotFound__footer}
        style={{ backgroundImage: `url(${FooterBackGroundImage})` }}
      ></footer>
    </article>
  );
}

import { useNavigate } from "react-router-dom";
import { pencilIcon } from "../../assets/svgs/icons/icons.svg";
import { useCollaborteurStore } from "../../stores/collaborateur/collaborateur.store";
import { useEtablissementStore } from "../../stores/etablissement/etablissement.store";
import { formatDate } from "../../utils";
import InformationSection from "../InformationSection/InformationSection";
import Button from "../global/Button/Button";

import styles from "./InscriptionRecapParticipation.module.scss";

type Props = {
  redirectTo?: string;
  hasModifierButton: boolean;
};
export default function InscriptionRecapParticipation(props: Props) {
  const { hasModifierButton, redirectTo } = props;

  //* zustand
  const { selectedCollaborateur } = useCollaborteurStore();
  const { selectedParticipation, selectedEtablissement } =
    useEtablissementStore();

  //* react-router-dom
  const navigate = useNavigate();
  function onClickModifier() {
    redirectTo && navigate(redirectTo);
  }
  return (
    <article
      className={styles.inscriptionRecapParticipation}
      is-recap-page={hasModifierButton ? "yes" : "no"}
    >
      <header className={styles.inscriptionRecapParticipation__header}>
        <h2>Participation Vis ma vie</h2>
        {hasModifierButton && (
          <Button
            text="Modifier"
            buttonBackGroundColor="white"
            textColor="dark"
            icon={pencilIcon}
            onClick={onClickModifier}
          />
        )}
      </header>
      <main className={styles.inscriptionRecapParticipation__main}>
        <div>
          <InformationSection
            label="Date de la journée Vis ma vie"
            text={
              selectedParticipation?.dateParticipation
                ? formatDate(selectedParticipation?.dateParticipation, {hasDayName:true})
                : "-"
            }
          ></InformationSection>
          <InformationSection
            label="Métier"
            text={selectedParticipation?.metierTitle || "-"}
          ></InformationSection>
        </div>
        <div>
          <InformationSection
            label="Etablissement"
            text={selectedParticipation?.etablissementName || "-"}
          ></InformationSection>
          <InformationSection
            label="Adresse établissement"
            text={selectedEtablissement?.localisation.address || "-"}
          ></InformationSection>
        </div>
        <div>
          <InformationSection
            label="Informations d'accès"
            text={selectedEtablissement?.information_access || "-"}
          ></InformationSection>
        </div>
      </main>
    </article>
  );
}

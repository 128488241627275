import { Accordion } from "@mantine/core";

import FooterBackGroundImage from "../../assets/png/error-page-decoration.png";

import LinkButton from "../../components/global/Button/LinkButton/LinkButton";
import styles from "./FAQPage.module.scss";

type Question = {
  title: string;
  answer: string;
};
export const FAQPage = () => {
  const questions: Question[] = [
    {
      title: `Devons-nous prévoir des animations particulières durant cette semaine ?`,
      answer: `Non, l'objectif est que les collaboratrices et collaborateurs
  partagent le quotidien de vos équipes sans perturber le bon
  fonctionnement de votre établissement. Nous vous invitons à vous
  assurer que les collaboratrices et collaborateurs soient en mesure
  de se rentre utiles et de participer activement aux missions de la
  journée.`,
    },
    {
      title: `Quelle est la différence entre Vis ma vie et Clariane immersion (ex Korian start) ?`,
      answer: `Clariane immersion (ex Korian start) est un programme d'intégration et d'observation pour
  les nouveaux collaborateurs et collaboratrices du groupe. Le
  projet Vis ma Vie est une immersion en établissement pour vivre
  durant une journée, un métier qui est plus proche du quotidien de
  nos résidents et résidentes.`,
    },
    {
      title: `J'ai dû effectuer un changement dans le planning de mes équipes. Comment modifier les disponibilités de l'établissement?`,
      answer: `Si votre annulation intervient durant la période d'inscription définie sur la plateforme, vous pouvez vous connecter sur la plateforme et procéder aux modifications. A l'issue de cette période les modifications ne sont plus possibles`,
    },
    {
      title: `Dois-je mettre à disposition une tenue de travail pour le/les collaborateurs des sièges qui se rendront dans mon établissement pour le "vis ma vie"`,
      answer: `Oui si le métier choisi implique le port d'une tenue`,
    },
    {
      title: `Est-ce que les alternants et les stagiaires peuvent participer à un Vie ma vie ?`,
      answer: `L'inscription est ouverte uniquement aux collaborateurs en CDI et aux collaborateurs en CDD de plus de 6 mois`,
    },
    {
      title:
        "Je rencontre une difficulté particulière, à qui puis-je m'adresser ?",
      answer:
        "Vous pouvez envoyer toutes vos questions, demandes à l'adresse email suivante : vismavie@clariane.com",
    },
  ];
  return (
    <article className={styles.faqPage}>
      <header className={styles.faqPage__headers}>
        <h1>Foire aux Questions</h1>
        <h2>
          Des questions ? Vous pouvez retrouver nos réponses à des questions
          fréquentes.
        </h2>
      </header>
      <main className={styles.faqPage__questions}>
        <Accordion
          defaultValue={["question0"]}
          className={styles.faqPage__questions__accordion}
          multiple={true}
        >
          {questions.map((question, index) => {
            return (
              <Accordion.Item
                value={`question${index}`}
                key={`FAQ_question_index_${index}`}
              >
                <Accordion.Control>{question.title}</Accordion.Control>
                <Accordion.Panel>{question.answer}</Accordion.Panel>
              </Accordion.Item>
            );
          })}
        </Accordion>
      </main>
      <LinkButton to="/" text="Revenir à l'accueil" />

      <footer
        style={{ backgroundImage: `url(${FooterBackGroundImage})` }}
      ></footer>
    </article>
  );
};

export const disponibilitesColumnsValues: {
  dayName: string;
  week: number;
  firstLabel: string;
  firstValue: string;
  secondLabel: string;
  secondValue: string;
  thirdLabel: string;
  thirdValue: string;
  fourthLabel: string;
  fourthValue: string;
}[] = [
    {
      dayName: "Lundi",
      week: 1,
      firstLabel: "3 juin",
      firstValue: "2024-06-03",
      secondLabel: "10 juin",
      secondValue: "2024-06-10",
      thirdLabel: "17 juin",
      thirdValue: "2024-06-17",
      fourthLabel: "24 juin",
      fourthValue: "2024-06-24",
    },
    {
      dayName: "Mardi",
      week: 1,
      firstLabel: "4 juin",
      firstValue: "2024-06-04",
      secondLabel: "11 juin",
      secondValue: "2024-06-11",
      thirdLabel: "18 juin",
      thirdValue: "2024-06-18",
      fourthLabel: "25 juin",
      fourthValue: "2024-06-25",
    },
    {
      dayName: "Mercredi",
      week: 1,
      firstLabel: "5 juin",
      firstValue: "2024-06-05",
      secondLabel: "12 juin",
      secondValue: "2024-06-12",
      thirdLabel: "19 juin",
      thirdValue: "2024-06-19",
      fourthLabel: "26 juin",
      fourthValue: "2024-06-26",
    },
    {
      dayName: "Jeudi",
      week: 1,
      firstLabel: "6 juin",
      firstValue: "2024-06-06",
      secondLabel: "13 juin",
      secondValue: "2024-06-13",
      thirdLabel: "20 juin",
      thirdValue: "2024-06-20",
      fourthLabel: "27 juin",
      fourthValue: "2024-06-27",
    },
    {
      dayName: "Vendredi",
      week: 1,
      firstLabel: "7 juin",
      firstValue: "2024-06-07",
      secondLabel: "14 juin",
      secondValue: "2024-06-14",
      thirdLabel: "21 juin",
      thirdValue: "2024-06-21",
      fourthLabel: "28 juin",
      fourthValue: "2024-06-28",
    },
    {
      dayName: "Lundi",
      week: 2,
      firstLabel: "10 juin",
      firstValue: "2024-06-08",
      secondLabel: "17 juin",
      secondValue: "2024-06-17",
      thirdLabel: "24 juin",
      thirdValue: "2024-06-24",
      fourthLabel: "1 juillet",
      fourthValue: "2024-07-01",
    },
    {
      dayName: "Mardi",
      week: 2,
      firstLabel: "11 juin",
      firstValue: "2024-06-11",
      secondLabel: "18 juin",
      secondValue: "2024-06-18",
      thirdLabel: "25 juin",
      thirdValue: "2024-06-25",
      fourthLabel: "2 juillet",
      fourthValue: "2024-07-02",
    },
    {
      dayName: "Mercredi",
      week: 2,
      firstLabel: "12 juin",
      firstValue: "2024-06-12",
      secondLabel: "19 juin",
      secondValue: "2024-06-19",
      thirdLabel: "26 juin",
      thirdValue: "2024-06-26",
      fourthLabel: "3 juillet",
      fourthValue: "2024-07-03",
    },
    {
      dayName: "Jeudi",
      week: 2,
      firstLabel: "13 juin",
      firstValue: "2024-06-13",
      secondLabel: "20 juin",
      secondValue: "2024-06-20",
      thirdLabel: "27 juin",
      thirdValue: "2024-06-27",
      fourthLabel: "4 juillet",
      fourthValue: "2024-07-04",
    },
    {
      dayName: "Vendredi",
      week: 2,
      firstLabel: "14 juin",
      firstValue: "2024-06-14",
      secondLabel: "21 juin",
      secondValue: "2024-06-21",
      thirdLabel: "28 juin",
      thirdValue: "2024-06-28",
      fourthLabel: "5 juillet",
      fourthValue: "2024-07-05",
    },

  ];
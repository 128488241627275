import React from "react";
import { Modal } from "@mantine/core";

import { Participation } from "../../schemas/collaborateur/collaborateur.schema";
import { formatDate } from "../../utils";
import Button from "../global/Button/Button";
import LinkButton from "../global/Button/LinkButton/LinkButton";

import styles from "./ConfirmeParticipationPopup.module.scss";
import { useEtablissementStore } from "../../stores/etablissement/etablissement.store";
import { useNavigate } from "react-router-dom";
import { rightIcon } from "../../assets/svgs/rightArrow";
import CustomeModal from "../CustomeModal/CustomeModal";
import { continuerModificationIcon } from "../../assets/svgs/icons/icons.svg";
type Props = {
  type: "addCollaborateur" | "editCollaborateur";
  partcipation: Participation | null;
  isOpen: boolean;
  setIsOpen:
    | React.Dispatch<React.SetStateAction<boolean>>
    | ((newState: boolean) => void);
  cancelButtonText: string;
  confirmButtonText: string;
  onConfirm?: () => void;
  navigateTo:string

};

export default function ConfirmeParticipationPopup(props: Props) {
  const {
    type,
    partcipation,
    isOpen,
    setIsOpen,
    cancelButtonText,
    confirmButtonText,
    onConfirm,
    navigateTo
  } = props;
  //* react-router-dom
  const navigate = useNavigate()
  const {setSelectedEtablissement,setSelectedParticipation} = useEtablissementStore()
  function onClickClose() {
    setSelectedEtablissement(null)
    setSelectedParticipation(null)
    setIsOpen(false);
  }


  return (
    <CustomeModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onClose={onClickClose}
    >
      <article className={styles.confirmeParticipationPopup}>
        <header className={styles.confirmeParticipationPopup__header}>
          <h1>Votre journée Vis ma vie</h1>
        </header>
        <main className={styles.confirmeParticipationPopup__main}>
          <section>
            <p>
              Vous vous apprêtez à passer une journée de 9h30 à 17h30 auprès de
              :
            </p>
          </section>
          <section
          className={styles.confirmeParticipationPopup__main__participation}
          >
            <strong>{partcipation && partcipation.metierTitle}</strong>
            <strong> {partcipation && partcipation.etablissementName} </strong>
            <strong>
              {partcipation &&
                formatDate(partcipation?.dateParticipation, {hasDayName:true})}
            </strong>
          </section>
          <section>
            <p>
              Afin de ne pas mobiliser inutilement les équipes en établissement,
              nous vous remercions d'annuler ou de modifier les choix de votre
              inscription qu'en cas d'extrême nécessité.
            </p>
          </section>
        </main>
        <footer className={styles.confirmeParticipationPopup__footer}>
          <Button
            text={cancelButtonText}
            textColor="dark"
            buttonBackGroundColor="white"
            onClick={onClickClose}
          />
          {type === "addCollaborateur" && (
            <LinkButton text={confirmButtonText} to={navigateTo} iconRight={rightIcon}  />
          )}
          {type === "editCollaborateur" && (
            <Button
              buttonBackGroundColor="blue"
              textColor="white"
              text={confirmButtonText}
              onClick={()=>navigate(navigateTo)}
              rightIcon={continuerModificationIcon}
            />
          )}
        </footer>
      </article>
    </CustomeModal>
  );
}

import { useState } from "react";
import { sendIcon } from "../../assets/svgs/icons/icons.svg";
import BreadCrumbNavbar from "../../components/BreadCrumbNavbar/BreadCrumbNavbar";
import InscriptionRecapInfoPersonnelles from "../../components/InscriptionRecapInfoPersonnelles/InscriptionRecapInfoPersonnelles";
import InscriptionRecapParticipation from "../../components/InscriptionRecapParticipation/InscriptionRecapParticipation";
import Button from "../../components/global/Button/Button";

import { useMsal } from "@azure/msal-react";
import ActionPopup from "../../components/ActionPopup/ActionPopup";
import UpdateCollaborateurPopup from "../../components/UpdateCollaborateurPopup/UpdateCollaborateurPopup";
import Loader from "../../components/global/Loader/Loader";
import { useAddCollaborateur } from "../../hooks/react-query/collaborateurs/collaborateurs.queries";
import { Direction } from "../../schemas/collaborateur/collaborateur.schema";
import {
  Etablissement
} from "../../schemas/etablissement/etablissemen.schema";
import { useCollaborteurStore } from "../../stores/collaborateur/collaborateur.store";
import { useEtablissementStore } from "../../stores/etablissement/etablissement.store";
import { getUserEmail } from "../../utils";
import styles from "./InscriptionRecapPage.module.scss";

export default function InscriptionRecapPage() {
  const [isOpenPopupConfirmation, setIsOpenPopupConfirmation] =
    useState<boolean>(false);
  const [isOpenModificationPopup, setIsOpenModificationPopupOpen] =
    useState<boolean>(false);
  const [isAddCollaborateurLoading, setIsAddCollaborateurLoading] =
    useState<boolean>(false);
  //* zustand
  const { selectedCollaborateur, setSeletecCollaborateur } =
    useCollaborteurStore();
  const { selectedEtablissement, selectedParticipation } =
    useEtablissementStore();
  const dateLimitInscriptionLitteral = process.env.REACT_APP_DATE_LIMIT_INSCRIPTION_LITTERAL;
  // * msal-react
  const { accounts } = useMsal();
  const { userEmail } = getUserEmail(accounts);

  //* react-query
  const { mutate, isLoading } = useAddCollaborateur(
    selectedEtablissement?._id as string,
    {
      nom: selectedCollaborateur?.nom as string,
      prenom: selectedCollaborateur?.prenom as string,
      email: selectedCollaborateur?.email as string,
      phone: selectedCollaborateur?.phone as string,
      hasDroitImage: selectedCollaborateur?.hasDroitImage as boolean,
      civilite: selectedCollaborateur?.civilite as string,
      fonction: selectedCollaborateur?.fonction as string,
      direction: selectedCollaborateur?.direction as Direction,
      participation: selectedParticipation,
      _id: selectedCollaborateur?._id as string,
      etablissement: selectedCollaborateur?.etablissement as Etablissement,
      userEmail: userEmail,
    },
    setIsAddCollaborateurLoading
  );

  function onClickConfirm() {
    setIsAddCollaborateurLoading(true);
    mutate();
  }
  function onClickEnvoyer() {
    setIsOpenPopupConfirmation(true);
  }
  if (isAddCollaborateurLoading) return <Loader />;
  return (
    <article className={styles.inscriptionRecapPageContainer}>
      <BreadCrumbNavbar step="recapitulatif" returnTo="/inscription" />
      <article className={styles.inscriptionRecapPage}>
        <header className={styles.inscriptionRecapPage__header}>
          <h1>Récapitulatif de votre inscription</h1>
          <section className={styles.inscriptionRecapPage__header__text}>
            <p>
              Vous trouverez ci-dessous un récapitulatif de vos informations :
            </p>
          </section>
        </header>
        <main className={styles.inscriptionRecapPage__main}>
          <InscriptionRecapInfoPersonnelles
            setIsOpenModificationPopup={setIsOpenModificationPopupOpen}
            hasModifierButton={true}
          />
          <InscriptionRecapParticipation
            redirectTo="/inscription/recapitulatif/recherche"
            hasModifierButton={true}
          />
          <Button
            type="button"
            text="Envoyer votre inscription"
            buttonBackGroundColor="linear-blue"
            textColor="white"
            rightIcon={sendIcon}
            onClick={onClickEnvoyer}
          />
        </main>
      </article>

      <UpdateCollaborateurPopup
        selectedCollaborateur={selectedCollaborateur}
        setSeletecCollaborateur={setSeletecCollaborateur}
        isOpen={isOpenModificationPopup}
        setIsOpen={setIsOpenModificationPopupOpen}
        cancelButtonText="Annuler"
        confirmButtonText="Valider"
        onConfirm={() => { }}
      />
      <ActionPopup
        isOpen={isOpenPopupConfirmation}
        setIsOpen={setIsOpenPopupConfirmation}
        title="Votre demande va être envoyée"
        textArray={[
          `Vous avez jusqu'au ${dateLimitInscriptionLitteral} pour modifier vos informations.`,
          "Une fois ce délai passé, merci de vous rapprocher directement de votre référent(e) Vis ma vie.",
          "Êtes vous sûr(e) de vouloir envoyer vos modifications ?",
        ]}
        cancelButtonText="Annuler"
        confirmButtonText="Envoyer"
        type="confirm"
        onConfirm={onClickConfirm}
      />
    </article>
  );
}

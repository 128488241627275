import collaborateur_background from "../../assets/png/collaborateur_background.png";
import RechercheBarStart from "../RechercheBarStart/RechercheBarStart";
import styles from "./InscriptionBackground.module.scss";

export default function InscriptionBackground() {
  return (
    <article className={styles.inscriptionBackground}>
      <section
        className={styles.inscriptionBackground__picture}
        style={{ backgroundImage: `url(${collaborateur_background})` }}
      >
        <section className={styles.titles_container}>
          <h1>Vis ma vie</h1>
          <h2>Trouver un établissement, près de chez vous</h2>
        </section>
        
        <RechercheBarStart />
      </section>
    </article>
  );
}

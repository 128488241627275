import axiosClient from "../../libs/axios";

async function getMetiers() {
  return axiosClient.get(`/drop-downs/metiers`);
}

const metiersServices = {
  getMetiers,
};
export default metiersServices;

import { To } from "react-router-dom";
import { noResults } from "../../assets/svgs/pictures/no-result.svg";
import BreadCrumbNavbar from "../../components/BreadCrumbNavbar/BreadCrumbNavbar";
import LinkButton from "../../components/global/Button/LinkButton/LinkButton";
import { useErrorStore } from "../../stores/errors.store";

import styles from "./ErrorPage.tsx.module.scss";
import { removeQuotesFromString } from "../../utils";

export default function ErrorPage() {
  const { backendError } = useErrorStore();
  return (
    <article className={styles.errorPage}>
      <BreadCrumbNavbar returnTo={ backendError === "Aucun résultat trouvé ..." ? '/': "/recherche"} step="none" />
      <header>
        <h1>{backendError && removeQuotesFromString(JSON.stringify(backendError, null, 2))}</h1>
      </header>
      <main>{noResults}</main>
    </article>
  );
}

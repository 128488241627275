import axiosClient from "../../libs/axios";
import {
  Collaborateur,
  Direction,
  ICollaborateur,
  Participation,
} from "../../schemas/collaborateur/collaborateur.schema";
 
export type AddCollaborateurResponseBody = {
  _id: string;
  civilite: string;
  nom: string;
  prenom: string;
  phone: string;
  fonction: string;
  direction: Direction;
  email: string;
  hasDroitImage: boolean;
  participation: Participation;
  userEmail:string;

};
async function addCollaborateur(
  etablissementId: string,
  requestBody: ICollaborateur
) {
  return await axiosClient.post<AddCollaborateurResponseBody>(
    `/collaborateurs/${etablissementId}`,
    {
      civilite: requestBody.civilite,
      nom: requestBody.nom,
      prenom: requestBody.prenom,
      phone: requestBody.phone,
      fonction: requestBody.fonction,
      direction: requestBody.direction,
      email: requestBody.email,
      hasDroitImage: requestBody.hasDroitImage,
      participation: requestBody.participation,
      userEmail: requestBody.userEmail,
    }
  );
}

export type UpdateCollaborateurRequestBody = {
  oldParticipation?: Participation;
  oldEtablissementId?: string;
  newParticipation?: Participation;

  hasDroitImage: boolean;
  direction: Direction;
  fonction: string;
  email: string;
  phone: string;
  prenom: string;
  nom: string;
  civilite: string;
  userEmail: string;
};

async function updateCollaborateur(
  params: {
    collaborateurId: string;
    etablissementId: string;
  },
  requestBody: UpdateCollaborateurRequestBody
) {
  const { collaborateurId, etablissementId } = params;
  let formatedRequestBody = {};
  if (
    requestBody.newParticipation &&
    requestBody.oldEtablissementId &&
    requestBody.oldParticipation
  ) {
    formatedRequestBody = {
      oldParticipation: {
        metierTitle: requestBody.oldParticipation?.metierTitle,
        dateParticipation: requestBody.oldParticipation?.dateParticipation,
        etablissementName: requestBody.oldParticipation?.etablissementName,
      },
      oldEtablissementId: requestBody.oldEtablissementId,
      newParticipation: requestBody.newParticipation,
      hasDroitImage: requestBody.hasDroitImage,
      direction: requestBody.direction,
      fonction: requestBody.fonction,
      email: requestBody.email,
      phone: requestBody.phone,
      prenom: requestBody.prenom,
      nom: requestBody.nom,
      civilite: requestBody.civilite,
      userEmail: requestBody.civilite,
    };
  } else {
    formatedRequestBody = {
      hasDroitImage: requestBody.hasDroitImage,
      direction: requestBody.direction,
      fonction: requestBody.fonction,
      email: requestBody.email,
      phone: requestBody.phone,
      prenom: requestBody.prenom,
      nom: requestBody.nom,
      civilite: requestBody.civilite,
      userEmail: requestBody.civilite,
    };
  }
  return await axiosClient.put<ICollaborateur>(
    `/collaborateurs/${collaborateurId}/${etablissementId}`,
    formatedRequestBody
  );
}

async function deleteCollaborateur(id: string) {
  return axiosClient.delete(`/collaborateurs/${id}`);
}
const collaborateurServices = {
  addCollaborateur,
  updateCollaborateur,
  deleteCollaborateur,
};
export default collaborateurServices;

import React, { useEffect, useState } from "react";
import Button from "../global/Button/Button";

import InputSection from "../inputs/InputSection/InputSection";
import DropdownSection from "../inputs/DropdownSection/DropdownSection";
import { useGetMetiers } from "../../hooks/react-query/dropdowns/metiers.queries";
import {
  EtablissementFilterValidationSchema,
  EtablissementFilterValidationType,
} from "../../schemas/etablissement/etablissementFitler.schema";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Loader from "../global/Loader/Loader";
import CalendarDropdownFilter from "../filters/CalendarDropdownFilter/CalendarDropdownFilter";
import MapsSearchDropdownFilter from "../filters/MapsSearchDropdownFilter/MapsSearchDropdownFilter";
import { LocalisationFilters, useEtablissementStore } from "../../stores/etablissement/etablissement.store";
import { MetierTitle } from "../../schemas/etablissement/etablissemen.schema";
import { useLocation, useNavigate } from "react-router-dom";

import styles from "./RechercheBarStart.module.scss";
import GradientButton from "../global/GradientButton/GradientButton";

export default function RechercheBarStart() {
  //* useState
  const [disponibilitesFilter, setDisponibilitesFilter] = useState<string[]>(
    []
  );
  const [localisation, setLocalisation] = useState<LocalisationFilters>({
    ville:"",
    lat:0,
    lon:0
  });

  //* react-router-dom
  const navigate = useNavigate();
  const {pathname} = useLocation()

  const isUsingInHomePage = (): boolean => {
    return pathname === "/"
  }

  //* react-query
  const { data: metiersData, isLoading: isLoadingMetier } = useGetMetiers();
  const metiersOptions = metiersData?.data?.list;

  //* react-form-hook
  const { setError, clearErrors, control, register, formState, handleSubmit } =
    useForm<EtablissementFilterValidationType>({
      resolver: yupResolver(EtablissementFilterValidationSchema),
    });

  const formErrors = formState.errors;

  //* zustand
  const { setSelectedMetierFilterValue, setSelectedLoclisationFilterValue } =
    useEtablissementStore();
  function onSubmit(data: EtablissementFilterValidationType) {
    setSelectedMetierFilterValue(data.metierFilter as MetierTitle);
    // setSelectedLoclisationFilterValue(ville);
    navigate("/recherche");
  }


  const sortMetierByAlphabeticOrder = (strArr: any[]): any[] => {
    if(!strArr?.length)  return []
    if(typeof strArr[0] === "string") return strArr?.sort((a, b) => a?.localeCompare(b))

    return strArr?.sort((a, b) => a?.label?.localeCompare(b?.label))
    
  }

  if (isLoadingMetier) return <Loader />;
  return (
    <article className={styles.rechercheBarStart}>
      <form
        className={styles.rechercheBarStart__form}
        onSubmit={handleSubmit(onSubmit)}
      >
        <main className={styles.rechercheBarStart__form__main}>
          <DropdownSection
            label="Métier*"
            name="metierFilter"
            placeholder="Sélectionner un métier"
            inputId="metierFilter"
            options={sortMetierByAlphabeticOrder(metiersOptions)}
            control={control}
            isUsingInHomePage={isUsingInHomePage()}
            hasIcon={true}
          />
          <div>
            <label
            className={styles.rechercheBarStart__form__main__titleLabel}
            >Disponibilité(s)</label>
            <CalendarDropdownFilter
              disponibilitesFilter={disponibilitesFilter}
              setDisponibilitesFilter={setDisponibilitesFilter}
              register={register}
              isUsingInHomePage={isUsingInHomePage()}
            />
          </div>
          <div
          >
            <label className={styles.rechercheBarStart__form__main__titleLabel}>Localisation*</label>
            <MapsSearchDropdownFilter
              name={"villeFilter"}
              placeholder="Indiquer une ville"
              inputId="villeFilter"
              register={register}
              formErrors={formErrors}
              setError={setError}
              clearErrors={clearErrors}
              localisation={localisation}
              setLocalisation={setLocalisation}
              isUsingInHomePage={isUsingInHomePage()}
            />
          </div>
        </main>

        <footer className={styles.rechercheBarStart__form__footer}>
          <p>*Champs obligatoires</p>
          <GradientButton text="Rechercher" type="submit" />
          {/* <Button
            text="Rechercher"
            buttonBackGroundColor="linear-blue"
            textColor="white"
            type="submit"
          /> */}
        </footer>
      </form>
    </article>
  );
}


interface IMetierOptions {
  label: string
  [x: string]: string
}
import {InferType, object, string} from 'yup'
//* Types and schemas related to the form validation for the Login page

export const LoginValidationSchema = object({
    email : string().required("Ce champ est obligatoire").email("Adresse email invalide"),
    password: string().required("Ce champ est obligatoire")
})


export type LoginValidationType = InferType<
  typeof LoginValidationSchema
>;
import { useQuery } from "@tanstack/react-query";
import civilitesServices from "../../../services/dropdowns/civilite.services";

export function useGetCivilites(){
    return useQuery({
        queryKey: ["dropDowns-civilites"],
        queryFn: civilitesServices.getCivilite,
        refetchInterval: 0,
        refetchOnWindowFocus: false,
      });
}
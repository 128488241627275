import React from 'react'
import VMVMetier from '../../components/static-blocks/VMVMetier/VMVMetier'

import styles from './Cloture.module.scss'
import VMVValeurs from '../../components/static-blocks/VMVValeurs/VMVValeurs'

const Cloture = () => {
  return (
    <article className={styles.cloture_container}>
        <article className={styles.cloture_header}>
            <h1>Vis ma vie</h1>
            <h2>Inscriptions {new Date().getFullYear()} clôturées</h2>
            <div className={styles.gradient_div} >
                <p>
                    <span>Oups, vous ne pouvez plus vous inscrire ! </span>
                    Les inscriptions se sont clôturées <span>le vendredi 2 juin.</span><br />
                    Vous pourrez retenter votre chance lors de la <span>prochaine édition</span>, prévue en {new Date().getFullYear() + 1}.<br />
                </p>
                <p>
                    Merci pour l’intérêt que vous avez porté à cet événement.<br />
                    On vous en reparle très vite ! <br />
                </p>
                <p>#confiance #initiative #responsabilité</p>

                
            </div>
        </article>
        <VMVMetier />
        <VMVValeurs />
    </article>
  )
}

export default Cloture
import { downloadIconWhite } from "../../assets/svgs/icons/icons.svg";
import Button from "../../components/global/Button/Button";
import VMVDescription from "../../components/static-blocks/VMVDescription/VMVDescription";
import VMVMetier from "../../components/static-blocks/VMVMetier/VMVMetier";
import VMVValeurs from "../../components/static-blocks/VMVValeurs/VMVValeurs";

import ContactSection from "../../components/ContactSection/ContactSection";
import InscriptionRecapInfoPersonnelles from "../../components/InscriptionRecapInfoPersonnelles/InscriptionRecapInfoPersonnelles";
import InscriptionRecapParticipation from "../../components/InscriptionRecapParticipation/InscriptionRecapParticipation";

import { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import ActionPopup from "../../components/ActionPopup/ActionPopup";
import UpdateCollaborateurPopup from "../../components/UpdateCollaborateurPopup/UpdateCollaborateurPopup";
import Loader from "../../components/global/Loader/Loader";
import {
  useDeleteCollaborateur,
  useUpdateCollaborateur,
} from "../../hooks/react-query/collaborateurs/collaborateurs.queries";
import {
  Direction
} from "../../schemas/collaborateur/collaborateur.schema";
import { useCollaborteurStore } from "../../stores/collaborateur/collaborateur.store";
import { useEtablissementStore } from "../../stores/etablissement/etablissement.store";
import styles from "./HomePageModification.module.scss";

export default function HomePageModification() {
  const [isOpenModificationPopup, setIsOpenModificationPopupOpen] =
    useState<boolean>(false);
  const [isOpenSuppressionPopup, setIsOpenSuppressionPopup] =
    useState<boolean>(false);

  //* zustand
  const { selectedCollaborateur, setSeletecCollaborateur } =
    useCollaborteurStore();
  const { selectedEtablissement } = useEtablissementStore();
  const { mutate, isLoading: isLoadingDeleteCollab } = useDeleteCollaborateur(
    selectedCollaborateur?._id as string
  );
  const dateLimitInscriptionLitteral = process.env.REACT_APP_DATE_LIMIT_INSCRIPTION_LITTERAL;
  // update
  const { mutate: updateCollaborateur, isLoading: isLoadingUpdate } =
    useUpdateCollaborateur({
      newEtablissementId: selectedEtablissement?._id as string,
      collaborateurId: selectedCollaborateur?._id as string,
      collaborateurBody: {
        civilite: selectedCollaborateur?.civilite as string,
        fonction: selectedCollaborateur?.fonction as string,
        hasDroitImage: selectedCollaborateur?.hasDroitImage as boolean,
        nom: selectedCollaborateur?.nom as string,
        email: selectedCollaborateur?.email as string,
        phone: selectedCollaborateur?.phone as string,
        userEmail: selectedCollaborateur?.userEmail as string,
        prenom: selectedCollaborateur?.prenom as string,
        direction: selectedCollaborateur?.direction as Direction,
      },
      // setIsLoading:setIsLoadingUpdateCollab
    });
  //* react-form-hook
  function openDeleteWarningPopup() {
    setIsOpenSuppressionPopup(true);
  }
  function onConfirmDelete() {
    // setIsDeleteCollaborateurLoading(true)
    mutate();
  }

  function onConfirmUpdateInfos() {
    // setIsLoadingUpdateCollab(true)
    updateCollaborateur();
  }

  // * react-to-print
  const componentToPrintRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentToPrintRef.current,
  });

  const {
    setSelectedLoclisationFilterValue,
    setSelectedMetierFilterValue,
    setSelectedDisponibilites,
    setEtablissementSearchFilter,
  } = useEtablissementStore();
  useEffect(() => {
    setSelectedLoclisationFilterValue({ lat: 0, lon: 0, ville: "" });
    setSelectedMetierFilterValue(null);
    setSelectedDisponibilites([]);
    setEtablissementSearchFilter("");
  }, []);
  if (isLoadingUpdate || isLoadingDeleteCollab) return <Loader />;
  return (
    <article className={styles.homePageModification}>
      <header className={styles.homePageModification__header}>
        <h1>Vis ma vie</h1>

        <h2>Votre inscription</h2>
      </header>
      <main className={styles.homePageModification__main}>
        <article className={styles.homePageModification__main__buttons}>
          <div
            className={
              styles.homePageModification__main__buttons__downloadAndAgendaButtonsContainer
            }
          >
            <Button
              text="Télécharger"
              buttonBackGroundColor="blue"
              textColor="white"
              icon={downloadIconWhite}
              onClick={handlePrint}
            />
          </div>
          <div
            className={
              styles.homePageModification__main__buttons__cancelInscriptionButtonContainer
            }
          >
            <Button
              text="Supprimer votre inscription"
              buttonBackGroundColor="red"
              textColor="white"
              type="button"
              onClick={openDeleteWarningPopup}
            />
          </div>
        </article>
        <article
          ref={componentToPrintRef}
          className={styles.homePageModification__main__infos}
        >
          <div
            className={styles.homePageModification__main__infos__contactInfos}
          >
            <ContactSection />
          </div>
          <div
            className={styles.homePageModification__main__infos__collabInfos}
          >
            <InscriptionRecapParticipation
              redirectTo="/modification/recherche"
              hasModifierButton={true}
            />
            <InscriptionRecapInfoPersonnelles
              setIsOpenModificationPopup={setIsOpenModificationPopupOpen}
              hasModifierButton={true}
            />
          </div>
        </article>
      </main>
      <VMVDescription />
      <VMVMetier />
      <VMVValeurs />
      <UpdateCollaborateurPopup
        selectedCollaborateur={selectedCollaborateur}
        setSeletecCollaborateur={setSeletecCollaborateur}
        isOpen={isOpenModificationPopup}
        setIsOpen={setIsOpenModificationPopupOpen}
        cancelButtonText="Annuler"
        confirmButtonText="Valider"
        onConfirm={onConfirmUpdateInfos}
      />
      <ActionPopup
        isOpen={isOpenSuppressionPopup}
        setIsOpen={setIsOpenSuppressionPopup}
        title="Annuler votre participation"
        textArray={[
          `Vous êtes sur le point d'annuler votre inscription.`,
          `Cette action supprimera l'ensemble des données et est irréversible.`,
          "",
          `Vous avez jusqu'au ${dateLimitInscriptionLitteral} pour vous réinscrire`,
          "",
          `Voulez-vous annuler votre participation ?`,
        ]}
        type="cancel"
        cancelButtonText="Annuler"
        confirmButtonText="Confirmation de l'annulation"
        onConfirm={onConfirmDelete}
      />
    </article>
  );
}

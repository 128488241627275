import BreadCrumbNavbar from "../../components/BreadCrumbNavbar/BreadCrumbNavbar";
import {
  checkBadgeIcon,
  downloadIcon,
} from "../../assets/svgs/icons/icons.svg";
import InscriptionRecapInfoPersonnelles from "../../components/InscriptionRecapInfoPersonnelles/InscriptionRecapInfoPersonnelles";
import InscriptionRecapParticipation from "../../components/InscriptionRecapParticipation/InscriptionRecapParticipation";
import ContactSection from "../../components/ContactSection/ContactSection";
import Button from "../../components/global/Button/Button";
import LinkButton from "../../components/global/Button/LinkButton/LinkButton";

import styles from "./InscriptionDemandeEnvoyeePage.module.scss";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";

export default function InscriptionDemandeEnvoyeePage() {

  // * react-to-print
  const componentToPrintRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentToPrintRef.current,
  });
  
  return (
    <article className={styles.inscriptionDemandeEnvoyeePageContainer}>
      <BreadCrumbNavbar step="demande-envoyee" returnTo="/inscription/recapitulatif" />
      <article className={styles.inscriptionDemandeEnvoyeePage}>
        <header className={styles.inscriptionDemandeEnvoyeePage__header}>
          <div className={styles.inscriptionDemandeEnvoyeePage__header__top}>
            {checkBadgeIcon}
            <h1>Demande envoyée</h1>
          </div>
          <section
            className={styles.inscriptionDemandeEnvoyeePage__header__text}
          >
            <p>
              Nous avons bien pris en compte votre inscription au Vis ma Vie et
              nous vous en remercions.
            </p>
            <p>Un mail de confirmation vous a été envoyé.</p>
            <br />
            <p>
              Afin de ne pas mobiliser inutilement les équipes en établissement,{" "}
            </p>
            <p>
              nous vous remercions d'annuler ou de modifier les choix de votre
              inscription qu'en cas d'extrême nécessité.
            </p>
          </section>
        </header>
        <main ref={componentToPrintRef} className={styles.inscriptionDemandeEnvoyeePage__main}>
          <InscriptionRecapInfoPersonnelles hasModifierButton={false} />
          <InscriptionRecapParticipation hasModifierButton={false} />
          <ContactSection />
        </main>
        <footer className={styles.inscriptionDemandeEnvoyeePage__footer}>
          <Button
            text="Télécharger"
            buttonBackGroundColor="white"
            textColor="blue"
            customBorderColor="blue"
            icon={downloadIcon}
            onClick={handlePrint}

          />
        </footer>
      </article>
      <nav className={styles.inscriptionDemandeEnvoyeePage__navButton}>
        <LinkButton text="Revenir à la page d'accueil" to="/" />
      </nav>
    </article>
  );
}

import { To, useNavigate } from "react-router-dom";
import {
  inscriptionNavbar,
  recapNavbar,
  validationNavbar,
} from "./BreadCrumbNavbar.icons";
import { greyleftArrowIcon } from "../../assets/svgs/icons/icons.svg";

import styles from "./BreadCrumbNavbar.module.scss";

type Props = { 
  step: "inscription" | "recapitulatif" | "demande-envoyee" | "none";
  returnTo : To
};
export default function BreadCrumbNavbar(props: Props) {
  const { step,returnTo } = props;
  const navigate = useNavigate();

  function handleGoBack() {
    navigate(returnTo); // go back one page
  }
  return (
    <nav className={styles.breadCrumbNavbar}>
      <button className={styles.breadCrumbNavbar__button} type="button" onClick={handleGoBack}>
        {greyleftArrowIcon}
        Retour
      </button>
      <section
      className={styles.breadCrumbNavbar__breadcrumbs}
      >
        {step === "inscription" && inscriptionNavbar}
        {step === "recapitulatif" && recapNavbar}
        {step === "demande-envoyee" && validationNavbar}
        {step === "none" && <></>}
      </section>
    </nav>
  );
}

import axiosClient from "../../libs/axios";

async function getDirections(){
    return axiosClient.get('/drop-downs/directions')
}

const directionsService = {
    getDirections
}

export default directionsService;
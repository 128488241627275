import { Loader as MantineLoader } from "@mantine/core";
import styles from './Loader.module.scss'

export default function Loader() {
  return (
    <article className={styles.loader}>
      <MantineLoader />
    </article>
  );
}

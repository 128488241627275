import {
  Control,
  FieldValues
} from "react-hook-form";
import { useGetCivilites } from "../../../hooks/react-query/dropdowns/civilites.queries";
import { useGetDirections } from "../../../hooks/react-query/dropdowns/directions.queries";
import Loader from "../../global/Loader/Loader";
import DropdownSection from "../../inputs/DropdownSection/DropdownSection";
import InputSection from "../../inputs/InputSection/InputSection";
import PhoneSection from "../../inputs/PhoneSection/PhoneSection";

import { CollaborateurFormSchemaType } from "../../../schemas/collaborateur/collaborateurForm.schema";
import styles from "./InscriptionInfoPersonnelles.module.scss";

type Props<FormSchemaType extends FieldValues> = {
  control: Control<FormSchemaType>;

};

export default function InscriptionInfoPersonnelles<FormSchemaType extends FieldValues>(props: Props<FormSchemaType>) {
  const { control } = props;
  const { data: civilitesData, isLoading: isLoadingCivilite } =
    useGetCivilites();
  const { data: directionsData, isLoading: isLoadingDirection } =
    useGetDirections();

  if (isLoadingCivilite || isLoadingDirection) return <Loader />;
  return (
    <article className={styles.inscriptionInfoPersonnelles}>
      <header className={styles.inscriptionInfoPersonnelles__header}>
        <h2>Informations personnelles *</h2>
      </header>
      <main className={styles.inscriptionInfoPersonnelles__main}>
        <article
          className={
            styles.inscriptionInfoPersonnelles__main__civiliteContainer
          }
        >
          <section>
            <DropdownSection<CollaborateurFormSchemaType>
              control={control}
              options={civilitesData?.data?.list}
              name={"civilite"}
              placeholder="Civilité"
              label="Civilité"
              inputId="collaborateur_inscription_civilite"
              hasIcon={false}
            />
          </section>
        </article>
        <article
          className={
            styles.inscriptionInfoPersonnelles__main__restOfInfosContainer
          }
        >
          <section>
            <InputSection<CollaborateurFormSchemaType>
              control={control}
              label="Nom"
              inputId="collaborateur_inscription_nom"
              inputType="text"
              name={"nom"}
              placeholder="Nom"
            />
            <InputSection<CollaborateurFormSchemaType>
              control={control}
              label="Email"
              inputId="collaborateur_inscription_email"
              inputType="email"
              name={"email"}
              placeholder="Email"
            />
            <InputSection<CollaborateurFormSchemaType>
              control={control}
              label="Fonction"
              inputId="collaborateur_inscription_fonction"
              inputType="text"
              name={"fonction"}
              placeholder="Indiquer la fonction que vous exercez"
            />
          </section>
          <section className={styles.inscriptionInfoPersonnelles__right}>
            <InputSection<CollaborateurFormSchemaType>
              control={control}
              label="Prénom"
              inputId="collaborateur_inscription_prenom"
              inputType="text"
              name={"prenom"}
              placeholder="Prénom"
            />

            <PhoneSection<CollaborateurFormSchemaType>
              control={control}
              label="Téléphone"
              inputId="collaborateur_inscription_telephone"
              name="phone"
              placeholder="Téléphone"
            />
            <DropdownSection<CollaborateurFormSchemaType>
              control={control}
              options={directionsData?.data?.list}
              name={"direction"}
              label="Direction"
              placeholder="Sélectionner le service dans lequel vous travaillez"
              inputId="collaborateur_inscription_direction"
              hasIcon={false}
            />
          </section>
        </article>
      </main>
    </article>
  );
}

import { useState } from "react";
import { Control, DeepMap, FieldError, UseFormClearErrors, UseFormRegister, UseFormSetError } from "react-hook-form";

import DropdownFilter from "../filters/DropdownFilter/DropdownFilter";
import { useGetTypeEtablissement } from "../../hooks/react-query/dropdowns/type-etablissement.queries";
import Loader from "../global/Loader/Loader";
import { useGetMetiers } from "../../hooks/react-query/dropdowns/metiers.queries";
import CalendarDropdownFilter from "../filters/CalendarDropdownFilter/CalendarDropdownFilter";
import MapsSearchDropdownFilter from "../filters/MapsSearchDropdownFilter/MapsSearchDropdownFilter";

import styles from "./EtablissementSearchBar.module.scss";
import { LocalisationFilters, useEtablissementStore } from "../../stores/etablissement/etablissement.store";
import { SearhcIcon } from "../../assets/svgs/search";

type Props = {
  register: UseFormRegister<any>;
  formErrors: DeepMap<any, FieldError>
  control: Control<any>;

  setError: UseFormSetError<any>;
  clearErrors: UseFormClearErrors<any>;

  localisation:LocalisationFilters
  setLocalisation : React.Dispatch<React.SetStateAction<LocalisationFilters>>

};

export default function EtablissementSearchBar(props: Props) {
  const { localisation,setLocalisation,setError,clearErrors,control,register,formErrors } = props;
  //* zustand
  const {selectedDisponibilites} = useEtablissementStore();
  const [disponibilitesFilter,setDisponibilitesFilter] = useState<string[]>(selectedDisponibilites)

  const { data: typeEtablissementData, isLoading: isLoadingTypeEtablissement } =
    useGetTypeEtablissement();
  const typeEtablissementOptions = typeEtablissementData?.data?.list;
  const { data: metiersData, isLoading: isLoadingMetier } = useGetMetiers();
  const metiersOptions = metiersData?.data?.list;

  const sortMetierByAlphabeticOrder = (strArr: any[]): any[] => {
    if(!strArr?.length)  return []
    if(typeof strArr[0] === "string") return strArr?.sort((a, b) => a?.localeCompare(b))

    return strArr?.sort((a, b) => a?.label?.localeCompare(b?.label))
    
  }

  if (isLoadingTypeEtablissement || isLoadingMetier) return <Loader />;
  return (
    <article className={styles.etablissementSearchBar}>
      <section className={styles.etablissementSearchBar__inputs}>
        <section
        className={styles.etablissementSearchBar__inputs__container}
        >
          <DropdownFilter
            name="metierFilter"
            placeholder="Sélectionner un métier"
            inputId="metierFilter"
            options={sortMetierByAlphabeticOrder(metiersOptions)}
            control={control}
          />
          <CalendarDropdownFilter
          disponibilitesFilter={disponibilitesFilter}
          setDisponibilitesFilter={setDisponibilitesFilter}
          register={register}
          />
          <MapsSearchDropdownFilter
          name={"villeFilter"}
          placeholder="Indiquer une ville"
          inputId="villeFilter"
          register={register}
          formErrors={formErrors}
          setError={setError}
          clearErrors={clearErrors}
          localisation={localisation}
          setLocalisation={setLocalisation}
          />
          
        </section>
        <button type={"submit"}>
          
          Rechercher
          {SearhcIcon}
        </button>
      </section>
      <section className={styles.etablissementSearchBar__filters}>
        <p>Filtrer par</p>
        <DropdownFilter
          name="typeEtablissement"
          placeholder="Tous les types d'établissements"
          inputId="typeEtablissement"
          options={typeEtablissementOptions as string[]}
          control={control}
          isWithBorder
          
        />
      </section>
    </article>
  );
}

import styles from "./VMVValeurs.module.scss";
import confianceImage from "../../../assets/png/confiance.png";
import homecare from "../../../assets/png/homecare.png";
import careseeker from "../../../assets/png/careseeker.png";

function VMVValeurs() {
  const valeursList: {
    image: string;
    text: string;
  }[] = [
    {
      image: confianceImage,
      text: `Être aux plus proches de nos valeurs : Confiance, Initiative, Responsabilité`,
    },
    {
      image: homecare,
      text: `Valoriser nos métiers terrains, au cœur du quotidien de nos résidents et de nos patients`,
    },
    {
      image: careseeker,
      text: `Renforcer notre cohésion d'équipe Sièges/Etablissements`,
    },
  ];
  return (
    <article className={styles.vmvValeurs}>
      <h1>
        Prendre soin de l'humanité de chacun dans les moments de fragilité
      </h1>
      <article className={styles.vmvValeurs__valeurs}>
        {valeursList.map((valeur, index) => {
          return (
            <section
              key={`VMV_valeur_${index}`}
              className={styles.vmvValeurs__valeurs__item}
            >
              <figure
                style={{ backgroundImage: `url(${valeur.image})` }}
              ></figure>
              <p>{valeur.text}</p>
            </section>
          );
        })}
      </article>
    </article>
  );
}

export default VMVValeurs;

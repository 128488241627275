import { Control,  DeepMap,  FieldError,  FieldValues,  Path,  UseFormRegister,  useController } from "react-hook-form";

import styles from "./InputSection.module.scss";
import { CollaborateurFormSchemaType } from "../../../schemas/collaborateur/collaborateurForm.schema";

type Props<FormSchemaType extends FieldValues> = {
  label: string;
  placeholder: string;
  inputId: string;
  inputType: React.HTMLInputTypeAttribute;
  name: Path<FormSchemaType>
  control:Control<any>
};
function InputSection<FormSchemaType extends FieldValues>(props: Props<FormSchemaType>) {
  const { label, placeholder, inputId, inputType, name,control} = props;

  const {field,formState} = useController<FormSchemaType>({name,control})
  const formErrors = formState.errors;
  const errorName = formErrors?.[name];

  return (
    <section className={styles.inputSection}>
      <label className={styles.inputSection__titleLabel} htmlFor={inputId}>
        {label}
      </label>
      <input
        type={inputType}
        id={inputId}
        placeholder={placeholder}
        {...field}
        has-error={errorName ? "yes" : "no"} // check the style sheet
      />
      <label className={styles.inputSection__errorLabel} htmlFor={inputId}>
        {formErrors?.[name]?.message?.toString()}
      </label>
    </section>
  );
}
export default InputSection;

import { Control, useController } from "react-hook-form";

import { AccountIcon } from "../../../assets/svgs/Account";
import { HomeIcon } from "../../../assets/svgs/Home";
import styles from "./DropdownFilter.module.scss";

export type DropdownTypeInBackend = {
  _id: string;
  label: string;
};

type Props = {
  options: string[] | DropdownTypeInBackend[];
  placeholder: string;
  inputId: string;
  name: string;
  control: Control<any>;
  isWithBorder?: boolean;
  areOptionsLoading?: boolean;
  onClick?: () => void
};

export default function DropdownFilter(props: Props) {
  const {
    options = [],
    placeholder,
    inputId,
    name,
    control,
    areOptionsLoading,
    isWithBorder
  } = props;

  const { field, formState } = useController<any>({ name, control });
  const formErrors = formState.errors;
  const errorName = formErrors?.[name]; return (
    <section className={styles.dropdown}>
      <div>
        <div className={styles.icon_wrapper}>
          {isWithBorder ? HomeIcon : AccountIcon}
        </div>
        <select
          has-error={errorName ? "yes" : "no"}
          {...field}
          id={inputId}
          defaultValue={""}
          style={{
            border: isWithBorder ? "1px solid #003E5C" : "inherit",
            borderRadius: isWithBorder ? "20px" : "6px",
          }}
        >
          {!areOptionsLoading ? (
            <>
              <option value={""} >
                {placeholder || ""}
              </option>

              {options.map((option, index) => {
                if (typeof option === "string")
                  return (
                    <option key={`dropdown_${index}`} value={option}>
                      {option}
                    </option>
                  );
                return (
                  // Here the type of option is DropdownTypeInBackend
                  <option key={`dropdown_${index}`} value={option?.label}>
                    {option?.label}
                  </option>
                );
              })}
            </>
          ) : (
            <option value={""}>Chargement...</option>
          )}
        </select>
      </div>


      <label className={styles.dropdown__errorLabel} htmlFor={inputId}>
        {formErrors?.[name]?.message?.toString()}
      </label>
    </section>
  );
}

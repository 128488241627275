import { useEffect } from "react";
import InscriptionAnnuleeNotification from "../../components/InscriptionAnnuleeNotification/InscriptionAnnuleeNotification";
import InscriptionBackground from "../../components/InscriptionBackground/InscriptionBackground";
import VMVDescription from "../../components/static-blocks/VMVDescription/VMVDescription";
import VMVMetier from "../../components/static-blocks/VMVMetier/VMVMetier";
import VMVResearch from "../../components/static-blocks/VMVResearch/VMVResearch";
import VMVValeurs from "../../components/static-blocks/VMVValeurs/VMVValeurs";
import { useNotificationStore } from "../../stores/notifications/notifications.store";
import styles from "./HomePageInscription.module.scss";
import { useEtablissementStore } from "../../stores/etablissement/etablissement.store";
export default function HomePageInscription() {
  const {
    showCancelInscriptionNotification,
    setShowCancelInscriptionNotification,
  } = useNotificationStore();

  const {
    setSelectedLoclisationFilterValue,
    setSelectedMetierFilterValue,
    setSelectedDisponibilites,
    setEtablissementSearchFilter,
  } = useEtablissementStore();
  useEffect(() => {
    setSelectedLoclisationFilterValue({ lat: 0, lon: 0, ville: "" });
    setSelectedMetierFilterValue(null);
    setSelectedDisponibilites([]);
    setEtablissementSearchFilter("");
  }, []);
  return (
    <article className={styles.homePageInscription}>
      <InscriptionAnnuleeNotification
        isNotifVisible={showCancelInscriptionNotification}
        setIsNotVisible={setShowCancelInscriptionNotification}
      />
      <InscriptionBackground />
      <VMVDescription />
      <VMVMetier />
      <VMVResearch />
      <VMVValeurs />
    </article>
  );
}

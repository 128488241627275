import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";

import InscriptionInfoPersonnelles from "../../components/InscriptionPage/InscriptionInfoPersonnelles/InscriptionInfoPersonnelles";
import InscriptionDroitImage from "../../components/InscriptionPage/InscriptionDroitImage/InscriptionDroitImage";

import Button from "../../components/global/Button/Button";
import {
  CollaborateurFormSchema,
  CollaborateurFormSchemaType,
} from "../../schemas/collaborateur/collaborateurForm.schema";
import { useCollaborteurStore } from "../../stores/collaborateur/collaborateur.store";
import { useEtablissementStore } from "../../stores/etablissement/etablissement.store";
import { Direction } from "../../schemas/collaborateur/collaborateur.schema";

import styles from "./InscriptionPage.module.scss";
import BreadCrumbNavbar from "../../components/BreadCrumbNavbar/BreadCrumbNavbar";
import { useMsal } from "@azure/msal-react";
import { formatDate, getUserEmail } from "../../utils";
import { Etablissement } from "../../schemas/etablissement/etablissemen.schema";

export default function InscriptionPage() {
  //* react-router-dom
  const navigate = useNavigate();
  //* zustand
  const { selectedEtablissement, selectedParticipation } =
    useEtablissementStore();
  const { setSeletecCollaborateur } = useCollaborteurStore();

  //* react-hook-form
  const { control, handleSubmit } = useForm<CollaborateurFormSchemaType>({
    resolver: yupResolver(CollaborateurFormSchema),
  });

  // * msal-react
  const { accounts } = useMsal();
  const { userEmail } = getUserEmail(accounts);

  //* react-query
  function onSubmit(data: CollaborateurFormSchemaType) {
    setSeletecCollaborateur({
      nom: data?.nom as string,
      prenom: data?.prenom as string,
      email: data?.email as string,
      phone: data?.phone as string,
      hasDroitImage: data?.hasDroitImage as boolean,
      civilite: data?.civilite as string,
      fonction: data?.fonction as string,
      direction: data?.direction as Direction,
      participation: selectedParticipation,
      userEmail: userEmail,
      etablissement: selectedEtablissement as Etablissement,
    });
    navigate("recapitulatif");
  }
  return (
    <article className={styles.inscriptionPage}>
      <BreadCrumbNavbar step="inscription" returnTo="/recherche" />
      <header className={styles.inscriptionPage__header}>
        <h1>Inscription</h1>
        <section className={styles.inscriptionPage__header__text}>
          <p>
            Afin de confirmer votre journée de 9h30 à 17h30 auprès de la
            {" "}{selectedParticipation?.metierTitle} de l'établissement{" "}
            {selectedParticipation?.etablissementName}, le
            {" "}{formatDate(selectedParticipation?.dateParticipation as Date, {
              hasFullWeekDay: true,hasDayName:true
            })}
            . Nous avons besoin de quelques informations. Une fois votre
            journée en établissement réalisée, vos données seront supprimées.
            <p>
              <strong>Attention : </strong>
              aucun frais de déplacement ne pourra être pris en compte par votre
              direction.
            </p>
          </p>
          <p>*Ces champs sont obligatoires.</p>
        </section>
      </header>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={styles.inscriptionPage__main}
      >
        <InscriptionInfoPersonnelles<CollaborateurFormSchemaType>
          control={control}
        />
        <InscriptionDroitImage<CollaborateurFormSchemaType>
          control={control}
          checkboxInputName="hasDroitImage"
        />
        <Button
          type="submit"
          text="Valider"
          buttonBackGroundColor="linear-blue"
          textColor="white"
        />
      </form>
      <footer className={styles.inscriptionPage__footer}></footer>
    </article>
  );
}

import React from 'react'
import styles from "./GradientButton.module.scss"

interface IGradientButtonProps {
    text: string
    onClick?: () => void
}


const GradientButton = ({ text, onClick }: IGradientButtonProps & React.HTMLProps<HTMLButtonElement> ) => {
  return (
    <button className={styles.gradient_button} onClick={onClick}>
        {text}
    </button>
  )
}

export default GradientButton
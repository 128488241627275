import {
  Control,
  DeepMap,
  FieldError,
  FieldValues,
  Path,
  UseFormRegister,
  useController,
} from "react-hook-form";
import styles from "./InscriptionDroitImage.module.scss";

type Props<FormSchemaType extends FieldValues> = {
  control: Control<any>;

  checkboxInputName: Path<FormSchemaType>;
};
export default function InscriptionDroitImage<
  FormSchemaType extends FieldValues
>(props: Props<FormSchemaType>) {
  const { control, checkboxInputName } = props;

  const { field, formState } = useController<FormSchemaType>({
    name: checkboxInputName,
    control,
  });
  const formErrors = formState.errors;
  const errorName = formErrors?.[checkboxInputName];

  return (
    <article className={styles.inscriptionDroitImage}>
      <header className={styles.inscriptionDroitImage__header}>
        <h2>Droit à l'image *</h2>
      </header>
      <main className={styles.inscriptionDroitImage__main}>
        <section className={styles.inscriptionDroitImage__main__text}>
          <p>Des photos pourront être prises dans le cadre de ce Vis ma Vie.</p>
          <p>
            Acceptez-vous que votre image soit diffusée dans le cadre de
            communications internes et externes ?
          </p>
        </section>
        <section className={styles.inscriptionDroitImage__main__inputs}>
          <div>
            <input
              id="droitImage_inscirption_radio_button_true"
              type="radio"
              {...field}
              value="true"
              defaultChecked={field.value === "true"} // Set defaultChecked based on field value
            />
            <label htmlFor="droitImage_inscirption_radio_button_true">
              Oui
            </label>
          </div>
          <div>
            <input
              id="droitImage_inscirption_radio_button_false"
              type="radio"
              {...field}
              value="false"
              defaultChecked={field.value === "false"} // Set defaultChecked based on field value
            />
            <label htmlFor="droitImage_inscirption_radio_button_false">
              Non
            </label>
          </div>
        </section>
        <label
          className={styles.inscriptionDroitImage__main__errorLabel}
          htmlFor={"droitImage_inscirption_radio_button_false"}
        >
          {formErrors?.[checkboxInputName]?.message?.toString()}
        </label>
      </main>
    </article>
  );
}

import axiosClient from "../../libs/axios";
import { Etablissement } from "../../schemas/etablissement/etablissemen.schema";

async function getEtablissements(
  params : {

    page :number,
    pageLimit :number,
    metierFilter: string,
    villeFilter: string,
    lat:number,
    lon:number,
    typeEtablissement?: string,
    disponibilites?: string[],
  }
) {
const {
page=1,
pageLimit=5,
metierFilter,
villeFilter, 
lat,
lon, 
typeEtablissement,
disponibilites,

} = params;
  let filter = `?page=${page}&pageLimit=${pageLimit}&metierFilter=${metierFilter}&lat=${lat}&lon=${lon}`;
    if (typeEtablissement) {
      filter = `${filter}&typeEtablissement=${typeEtablissement}`;
    }

    if (disponibilites && disponibilites.length > 0) {
      disponibilites.forEach((date) => {
        filter = `${filter}&disponibilites=${date}`;
      });
    }

  return await axiosClient.get<{ etablissements: Etablissement[],total:number }>(
    `/etablissements${filter}`
  );
}

const etablissementService = {
  getEtablissements,
};

export default etablissementService;

import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

interface ErrorState {
  backendError: any;
  setBackendError: (newState: any) => void;
}

export const useErrorStore = create<ErrorState>()(
  devtools(
    persist(
      (set) => ({
        backendError: '',
        setBackendError: (newState) =>
          set((state) => ({ backendError: newState })),
      }),

      {
        name: "error-state-storage",
      }
    )
  )
);

import { useEtablissementStore } from "../../stores/etablissement/etablissement.store";
import { formatPhoneNumber } from "../../utils";
import InformationSection from "../InformationSection/InformationSection";

import styles from "./ContactSection.module.scss";

export default function ContactSection() {
  const { selectedEtablissement } = useEtablissementStore();
  return (
    <article className={styles.contactSection}>
      <header className={styles.contactSection__header}>
        <h2>Contact</h2>
      </header>
      <main className={styles.contactSection__main}>
        <section className={styles.contactSection__main__infosRef}>
          <header className={styles.contactSection__main__infosRef__header}>
            <h3>Informations référent(e) vis ma vie</h3>
          </header>
          <main className={styles.contactSection__main__infosRef__main}>
            <div>
              <InformationSection
                label="NOM du ou de la référent(e)"
                text={
                  selectedEtablissement?.information_referent?.nom_referent ||
                  "-"
                }
              />
              <InformationSection
                label="Email"
                text={
                  selectedEtablissement?.information_referent?.email_referent ||
                  "-"
                }
              />
            </div>
            <div>
              <InformationSection
                label="Prénom du ou de la référent(e)"
                text={
                  selectedEtablissement?.information_referent
                    ?.prenom_referent || "-"
                }
              />
              <InformationSection
                label="Téléphone"
                text={formatPhoneNumber(
                  selectedEtablissement?.information_referent?.phone_referent
                )}
              />
            </div>
          </main>
        </section>
        <section className={styles.contactSection__main__infosDE}>
          <header className={styles.contactSection__main__infosDE__header}>
            <h3>Informations Directeur(trice) d'établissement</h3>
          </header>
          <main className={styles.contactSection__main__infosDE__main}>
            <div>
              <InformationSection
                label="NOM du ou de la DE"
                text={selectedEtablissement?.information_DE?.nom_de || "-"}
              ></InformationSection>
              <InformationSection
                label="Email"
                text={selectedEtablissement?.information_DE?.email_de || "-"}
              ></InformationSection>
            </div>
            <div>
              <InformationSection
                label="Prénom du ou de la DE"
                text={selectedEtablissement?.information_DE?.prenom_de || "-"}
              ></InformationSection>
              <InformationSection
                label="Téléphone"
                text={formatPhoneNumber(
                  selectedEtablissement?.information_DE?.phone_de
                )}
              ></InformationSection>
            </div>
          </main>
        </section>
      </main>
    </article>
  );
}

import React, { useState } from "react";
import { useEtablissementStore } from "../../stores/etablissement/etablissement.store";
import DisponibiliteButton from "../filters/CalendarDropdownFilter/DisponibiliteButton/DisponibiliteButton";
import { disponibilitesColumnsValues } from "../filters/CalendarDropdownFilter/utils";
import styles from "./CalenderDropdownPopup.module.scss";

type Props = {
  disponibilitesFilter: string[];
  setDisponibilitesFilter: React.Dispatch<React.SetStateAction<string[]>>;
  isPopupVisible: boolean;
  setIsPopupVisible: React.Dispatch<React.SetStateAction<boolean>>;
};
export default function CalenderDropdownPopup(props: Props) {
  const {
    disponibilitesFilter,
    setDisponibilitesFilter,
    isPopupVisible,
    setIsPopupVisible,
  } = props;

  const { selectedDisponibilites, setSelectedDisponibilites } = useEtablissementStore()
  const [tempDisponibilites, setTempDisponibilites] =
    useState<string[]>(disponibilitesFilter);
  function handleConfirmDisponibiliteFilter() {
    setDisponibilitesFilter((current) => [...tempDisponibilites]);
    if (tempDisponibilites.length === 0) {
      setSelectedDisponibilites([])
      setIsPopupVisible(false)
      return;
    }
    setSelectedDisponibilites([...tempDisponibilites])
    setIsPopupVisible(false)
  }
  const uniqueDayNames = disponibilitesColumnsValues.filter((value, index, self) =>
    self.findIndex((v) => v.dayName === value.dayName) === index
  );

  return (
    <section className={styles.calendarDropdownFilter__popup}>
      <header className={styles.calendarDropdownFilter__popup__header}>
        <h1>Sélectionner vos disponibilités.</h1>
      </header>
      <main className={styles.calendarDropdownFilter__popup__main}>
        {uniqueDayNames.map((disponibilitesColumn, index) => {
          return (
            <article
              className={
                styles.calendarDropdownFilter__popup__main__disponibilitesColumn
              }
              key={`disponibilitesColumnsValues_filter_${index}`}
            >
              <label>{disponibilitesColumn.dayName}</label>
              <DisponibiliteButton
                label={disponibilitesColumn.firstLabel}
                value={disponibilitesColumn.firstValue}
                disponibilitesFilter={tempDisponibilites}
                setDisponibilitesFilter={setTempDisponibilites}
                weeklist={disponibilitesColumn.week}
              />
              <DisponibiliteButton
                label={disponibilitesColumn.secondLabel}
                value={disponibilitesColumn.secondValue}
                disponibilitesFilter={tempDisponibilites}
                setDisponibilitesFilter={setTempDisponibilites}
                weeklist={disponibilitesColumn.week}
              />
              <DisponibiliteButton
                label={disponibilitesColumn.thirdLabel}
                value={disponibilitesColumn.thirdValue}
                disponibilitesFilter={tempDisponibilites}
                setDisponibilitesFilter={setTempDisponibilites}
                weeklist={disponibilitesColumn.week}
              />
              <DisponibiliteButton
                label={disponibilitesColumn.fourthLabel}
                value={disponibilitesColumn.fourthValue}
                disponibilitesFilter={tempDisponibilites}
                setDisponibilitesFilter={setTempDisponibilites}
                weeklist={disponibilitesColumn.week}
              />
            </article>
          );
        })}
      </main>
      <footer className={styles.calendarDropdownFilter__popup__footer}>
        <button
          type="button"
          button-type="annuler"
          onClick={() => setIsPopupVisible(false)}
        >
          Annuler
        </button>
        <button
          type="button"
          button-type="valider"
          onClick={handleConfirmDisponibiliteFilter}
        >
          Valider
        </button>
      </footer>
    </section>
  );
}

import { useRef, useState } from "react";
import { downloadIcon, sendIcon } from "../../assets/svgs/icons/icons.svg";
import ActionPopup from "../../components/ActionPopup/ActionPopup";
import ContactSection from "../../components/ContactSection/ContactSection";
import InscriptionRecapInfoPersonnelles from "../../components/InscriptionRecapInfoPersonnelles/InscriptionRecapInfoPersonnelles";
import InscriptionRecapParticipation from "../../components/InscriptionRecapParticipation/InscriptionRecapParticipation";
import Button from "../../components/global/Button/Button";
import Loader from "../../components/global/Loader/Loader";
import { useUpdateCollaborateur } from "../../hooks/react-query/collaborateurs/collaborateurs.queries";
import {
  Direction,
  Participation,
} from "../../schemas/collaborateur/collaborateur.schema";
import { useCollaborteurStore } from "../../stores/collaborateur/collaborateur.store";
import { useEtablissementStore } from "../../stores/etablissement/etablissement.store";

import { useReactToPrint } from "react-to-print";
import BreadCrumbNavbar from "../../components/BreadCrumbNavbar/BreadCrumbNavbar";

import styles from "./RecapModificationpage.module.scss";

export default function RecapModificationpage() {
  const [isOpenPopupConfirmation, setIsOpenPopupConfirmation] =
    useState<boolean>(false);

  const dateLimitInscriptionLitteral = process.env.REACT_APP_DATE_LIMIT_INSCRIPTION_LITTERAL;

  //* zustand
  const { selectedCollaborateur } = useCollaborteurStore();
  const {
    oldEtablissement,
    oldEtablissementId,
    oldParticipation,

    selectedEtablissement,
    selectedParticipation,
  } = useEtablissementStore();
  //* react-query
  const { mutate, isLoading } = useUpdateCollaborateur({
    collaborateurBody: {
      oldParticipation: oldParticipation as Participation,
      oldEtablissementId: oldEtablissementId as string,
      newParticipation: selectedParticipation as Participation,

      hasDroitImage: selectedCollaborateur?.hasDroitImage as boolean,
      direction: selectedCollaborateur?.direction as Direction,
      fonction: selectedCollaborateur?.fonction as string,
      email: selectedCollaborateur?.email as string,
      phone: selectedCollaborateur?.phone as string,
      prenom: selectedCollaborateur?.prenom as string,
      nom: selectedCollaborateur?.nom as string,
      civilite: selectedCollaborateur?.civilite as string,
      userEmail: selectedCollaborateur?.userEmail as string,
    },
    collaborateurId: selectedCollaborateur?._id as string,
    newEtablissementId: selectedEtablissement?._id as string,
  });
  function onClickConfirm() {
    mutate();
  }
  function onClickEnvoyer() {
    setIsOpenPopupConfirmation(true);
  }

  // * react-to-print
  const componentToPrintRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentToPrintRef.current,
  });
  if (isLoading) return <Loader />;
  return (
    <article className={styles.recapModificationpage}>
      <BreadCrumbNavbar step="none" returnTo="/modification/recherche" />
      <header className={styles.recapModificationpage__header}>
        <h1>Récapitulatif des modifications</h1>
        <section className={styles.recapModificationpage__header__text}>
          <p>
            Nous avons bien pris en compte votre inscription au Vis ma Vie et
            nous vous en remercions.
          </p>
          <p>Un mail de confirmation vous a été envoyé.</p>
          <br />
          <p>
            Afin de ne pas mobiliser inutilement les équipes en établissement,
          </p>
          <p>
            nous vous remercions d'annuler ou de modifier les choix de votre
            inscription qu'en cas d'extrême nécessité.
          </p>
        </section>
      </header>
      <main className={styles.recapModificationpage__main}>
        <main ref={componentToPrintRef}

          className={styles.recapModificationpage__main__infos}
        >
          <InscriptionRecapInfoPersonnelles hasModifierButton={false} />
          <InscriptionRecapParticipation hasModifierButton={false} />
          <ContactSection />
        </main>
        <footer className={styles.recapModificationpage__main__footer}>
          <Button
            text="Télécharger"
            buttonBackGroundColor="white"
            textColor="blue"
            customBorderColor="blue"
            icon={downloadIcon}
            onClick={handlePrint}
          />
        </footer>
      </main>
      <nav className={styles.recapModificationpage__nav}>
        <Button
          text="Envoyer les modifications"
          buttonBackGroundColor="linear-blue"
          textColor="white"
          rightIcon={sendIcon}
          onClick={onClickEnvoyer}
          type="button"
        />
      </nav>

      <ActionPopup
        isOpen={isOpenPopupConfirmation}
        setIsOpen={setIsOpenPopupConfirmation}
        title="Vos modifications vont être envoyées"
        textArray={[
          `Vous avez jusqu'au ${dateLimitInscriptionLitteral} pour modifier vos informations.`,
          `Une fois ce délai passé, merci de vous rapprocher directement de votre référent(e) Vis ma vie.`,
          `Êtes vous sûr(e) de vouloir envoyer vos modifications ?`,
        ]}
        cancelButtonText="Annuler"
        confirmButtonText="Envoyer"
        type="confirm"
        onConfirm={onClickConfirm}
      />
    </article>
  );
}

import { Link, LinkProps } from "react-router-dom";
import styles from "./LinkButton.module.scss";

type Props = {
  text: string;
  to: LinkProps["to"];
  iconLeft?: JSX.Element;
  iconRight?: JSX.Element;
};
export default function LinkButton(props: Props) {
  const { text, to, iconLeft, iconRight } = props;

  return (
    <Link className={styles.linkButton} to={to}>
      {iconLeft && (
        <>
          {iconLeft}
        {/* This is the space between the left icon and the text */}
          {` `} 
        </>
      )}
      {text}
      {iconRight && (
        <>
        {/* This is the space between the right icon and the text */}
          {` `}
          {iconRight}
        </>
      )}
    </Link>
  );
}

import React, { useState } from 'react'
import styles from "./scrollbutton.module.scss"
import {ArrowUpIcon} from "../../assets/svgs/icons/arrow_up.js"

const ScrollButton = () => {
   const [visible, setVisible] = useState<boolean>(false)

  const toggleVisible = (): void => {
    const scrolled = document.documentElement.scrollTop
    if (scrolled > 450) {
      setVisible(true)
    } else if (scrolled <= 450) {
      setVisible(false)
    }
  }

  const scrollToTop = (): void => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  window.addEventListener('scroll', toggleVisible)

  return (
    <button
      onClick={scrollToTop}
      className={styles.scroll_button}
      style={{
        display: visible ? "flex": "none"
      }}
    >
        {ArrowUpIcon}
    </button>
  )
}

export default ScrollButton
import axiosClient from "../../libs/axios";

async function getMapSearchResults(query: string) {
  return axiosClient.get(`/azure-maps/search/?query=${query}`);
}

const mapsSearchServices = {
  getMapSearchResults,
};

export default mapsSearchServices;

import signatureEmail from "../../../assets/jpg/email_signature.jpg";
import {
  ICollaborateur,
  Participation,
} from "../../../schemas/collaborateur/collaborateur.schema";
import { formatDate, formatPhoneWithoutPrefix } from "../../../utils";

const dateLimitInscription = process.env.REACT_APP_DATE_LIMIT_INSCRIPTION;
const websiteUrl = process.env.REACT_APP_WEBSITE_URL;

export function generateInscriptionEmail_Etablissement(infos: {
  collaborateurInfo: ICollaborateur;
  participation: Participation;
}) {
  const { collaborateurInfo, participation } = infos;
  return `<article>
      <p>Bonjour,</p>

      <p>
        Un collaborateur vient de s'inscrire sur l'une de vos sessions
        disponibles.
      </p>

      <p>Vous trouverez ci-dessous le récapitulatif de son inscription :</p>

      <p>Nom : ${collaborateurInfo.prenom}</p>
      <p>Prénom : ${collaborateurInfo.nom}</p>

      <p>Date de la journée réservée : ${formatDate(participation.dateParticipation, {
    hasDayName: true,
    hasYear: true,
  })} </p>
      <p>Métier réservé : ${participation.metierTitle}</p>
      <p>Adresse email de l'inscrit : ${collaborateurInfo.email} </p>
      <p>Téléphone de l'inscrit : +33 ${formatPhoneWithoutPrefix(collaborateurInfo.phone)} </p>

      <p>
        L'accueil du collaborateur se fera au sein de votre établissement de
        9h30 à 17h30. Si vous ne pouvez plus l'accueillir, merci de le contacter
        directement et de prévenir l'équipe Vis ma Vie au mail suivant :
        vismavie@clariane.com
      </p>

      <p>Bien à vous.</p>

      <p>L'équipe projet “Vis ma vie”</p>
      <img
        src="${signatureEmail}"
        alt="signature_logo"
        width={100}
        height={100}
      ></img>
    </article > `;
}

export function generateAnnulationEmail_Etablissement(infos: {
  collaborateurInfo: ICollaborateur;
  participation: Participation;
}) {
  const { collaborateurInfo, participation } = infos;
  return `<article>
      <p>Bonjour,</p>

      <p>
        Un collaborateur vient d'annuler son inscription à l'évènement Vis ma
        vie.
      </p>

      <p>Vous trouverez ci-dessous le récapitulatif de son annulation :</p>
      <p>Nom : ${collaborateurInfo.prenom}</p>
      <p>Prénom : ${collaborateurInfo.nom}</p>

      <p>Date de la journée réservée : ${formatDate(participation.dateParticipation, {
    hasDayName: true,
    hasYear: true,
  })} </p>
      <p>Métier réservé : ${participation.metierTitle}</p>
      <p>Adresse email de l'inscrit : ${collaborateurInfo.email} </p>
      <p>Téléphone de l'inscrit : +33 ${formatPhoneWithoutPrefix(collaborateurInfo.phone)} </p>

      <p>
        Le collaborateur ayant annulé son inscription, la session disponible est
        automatiquement réouverte aux réservations sur la plateforme.
      </p>

      <p>Bien à vous.</p>

      <p>L'équipe projet “Vis ma vie”</p>
      <img
      src="${signatureEmail}"
      alt="signature_logo"
      width={100}
      height={100}
    ></img>
  </article > `;
}

export function generateModificationEmail_normalUpdate_Etablissement(infos: {
  collaborateurInfo: ICollaborateur;
  participation: Participation;
}) {
  const { collaborateurInfo, participation } = infos;
  return `<article>
      <p>Bonjour,</p>
      <p>
        Un collaborateur participant à l'évènement Vis ma Vie au sein de votre
        établissement vient de modifier ses informations personnelles.
      </p>

      <p>Vous trouverez ci-dessous le récapitulatif de ses informations :</p>
      <p>Nom : ${collaborateurInfo.prenom}</p>
      <p>Prénom : ${collaborateurInfo.nom}</p>
      <p>
      Date de la journée réservée :${formatDate(
    participation?.dateParticipation,
    {
      hasDayName: true,
      hasYear: true,
    }
  )}
      </p>
      <p>Métier réservé : ${participation?.metierTitle}</p>
      <p>Adresse email de l'inscrit : ${collaborateurInfo.email}</p>
      <p>Téléphone de l'inscrit : +33 ${formatPhoneWithoutPrefix(collaborateurInfo.phone)}</p>

      <p>
        L'accueil du collaborateur se fera au sein de votre établissement de
        9h30 à 17h30. Si vous ne pouvez plus l'accueillir, merci de le contacter
        directement et de prévenir l'équipe Vis ma Vie au mail suivant :
        vismavie@clariane.com
      </p>

      <p>Bien à vous.</p>

      <p>L'équipe projet “Vis ma vie”</p>
      <img
      src="${signatureEmail}"
      alt="signature_logo"
      width={100}
      height={100}
    ></img>
  </article > `;


}

import { useQuery } from "@tanstack/react-query";
import typeEtablissementsServices from "../../../services/dropdowns/type-etablissement.service";

export function useGetTypeEtablissement() {
  return useQuery({
    queryKey: ["dropDowns-typeEtablissement"],
    queryFn: typeEtablissementsServices.getTypeEtablissements,
    refetchInterval: 0,
    refetchOnWindowFocus: false,
  });
}

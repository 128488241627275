import { useQuery } from "@tanstack/react-query";
import directionsService from "../../../services/dropdowns/directions.service";

export function useGetDirections() {
  return useQuery({
    queryKey: ["dropDowns-directions"],
    queryFn: directionsService.getDirections,
    refetchInterval: 0,
    refetchOnWindowFocus: false,
  });
}

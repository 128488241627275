import FooterBackGroundImage from "../../../assets/png/error-page-decoration.png";
import styles from './Footer.module.scss'
export default function Footer() {
  return (
    <footer
    className={styles.footerComponent}
      style={{ backgroundImage: `url(${FooterBackGroundImage})` }}
    ></footer>
  );
}

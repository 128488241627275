import { set, useForm } from "react-hook-form";
import EtablissementSearchBar from "../../components/EtablissementSearchBar/EtablissementSearchBar";
import EtablissementList from "../../components/EtablissementList/EtablissementList";
import {
  EtablissementFilterValidationSchema,
  EtablissementFilterValidationType,
} from "../../schemas/etablissement/etablissementFitler.schema";
import etablissementService from "../../services/etablissement/etablissement.service";
import { useEffect, useState } from "react";
import {
  Etablissement,
  MetierTitle,
} from "../../schemas/etablissement/etablissemen.schema";
import {
  LocalisationFilters,
  useEtablissementStore,
} from "../../stores/etablissement/etablissement.store";
import Loader from "../../components/global/Loader/Loader";

import styles from "./RechercheEtablissementPage.module.scss";
import PaginationFooter from "../../components/PaginationFooter/PaginationFooter";
import ConfirmeParticipationPopup from "../../components/Popups/ConfirmeParticipationPopup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { useErrorStore } from "../../stores/errors.store";

type Props = {
  onValidationLink: string;
  currentFlow: "inscription" | "modification";
};
export default function RechercheEtablissementPage(props: Props) {
  const { onValidationLink, currentFlow } = props;

  // * react-router-dom
  const navigate = useNavigate();
  //* zustand
  const {
    selectedDisponibilites,
    setEtablissementSearchFilter,
    selectedParticipation,
    setSelectedLoclisationFilterValue,
    setSelectedMetierFilterValue,
    localisationFilterValue,
    metierFilterValue,
  } = useEtablissementStore();

  //* useState
  const [page, setPage] = useState<number>(1);
  const [pageLimit, setPageLimit] = useState<number>(5);
  const [totalEtablissements, setTotalEtablissements] = useState<number>(0);
  const [etablissements, setEtablissements] = useState<Etablissement[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [localisation, setLocalisation] = useState<LocalisationFilters>({
    ville: localisationFilterValue?.ville as string,

    lat: localisationFilterValue?.lat as number,
    lon: localisationFilterValue?.lon as number,
  });
  const [hasFetched, setHasFetched] = useState<boolean>(false);

  //* react-form-hook
  const {
    setError,
    clearErrors,
    control,
    register,
    formState,
    handleSubmit,
    watch,
  } = useForm<EtablissementFilterValidationType>({
    resolver: yupResolver(EtablissementFilterValidationSchema),
    defaultValues: {
      metierFilter: metierFilterValue as MetierTitle,
      villeFilter: localisationFilterValue?.ville as string,
    },
  });
  const formErrors = formState.errors;
  const watchTypeEtablissement = watch("typeEtablissement");
  const watchVilleFilter = watch("villeFilter");
  const watchMetierFilter = watch("metierFilter");
  const watchdisponibilitesFilter = watch("disponibilitesFilter");

  //* onSubmit function
  async function onSubmit(data: EtablissementFilterValidationType) {
    const { metierFilter, typeEtablissement } = data;
    const { etablissement, totalEtablissements, filter } =
      await fetchEtablissements({
        page: page,
        pageLimit: pageLimit,
        metierFilter: metierFilter,
        villeFilter: localisation.ville as string,
        lat: localisation.lat as number,
        lon: localisation.lon as number,
        typeEtablissement: typeEtablissement,
        disponibilites: selectedDisponibilites,
      });
    setPage(1);
    setEtablissements(etablissement);
    setTotalEtablissements(totalEtablissements);
    setEtablissementSearchFilter(filter);
    setSelectedMetierFilterValue(metierFilter as MetierTitle)
    setIsPopupOpen(false);
    if (etablissement.length === 0) {
      setBackendError("Aucun résultat trouvé ...");
      navigate("/error");
    }
    // setVille("")
    // setSelectedLoclisationFilterValue('')
  }

  useEffect(() => {
    async function fetch() {
      const { etablissement, totalEtablissements, filter } =
        await fetchEtablissements({
          page: page,
          pageLimit: pageLimit,
          metierFilter: watchMetierFilter,
          villeFilter: localisation.ville as string,
          lat: localisation.lat as number,
          lon: localisation.lon as number,
          typeEtablissement: watchTypeEtablissement,
          disponibilites: selectedDisponibilites,
        });

      setEtablissements(etablissement);
      setTotalEtablissements(totalEtablissements);
      setEtablissementSearchFilter(filter);
      // setVille('')
      // setSelectedLoclisationFilterValue('')
    }
  fetch();

  }, [page, pageLimit, watchTypeEtablissement]);

  //* react-query
  const { setBackendError } = useErrorStore();
  async function fetchEtablissements(params: {
    page: number;
    pageLimit: number;
    metierFilter: string;
    villeFilter: string;
    lat: number;
    lon: number;
    typeEtablissement?: string;
    disponibilites?: string[];
  }) {
    const {
      page,
      pageLimit,
      metierFilter,
      villeFilter,
      lat,
      lon,
      typeEtablissement,
      disponibilites,
    } = params;
    setIsLoading(true);
    let filter = `?page=${page}&pageLimit=${pageLimit}&metierFilter=${metierFilter}&lat=${lat}&lon=${lon}`;
    if (typeEtablissement) {
      filter = `${filter}&typeEtablissement=${typeEtablissement}`;
    }
    if (disponibilites && disponibilites.length > 0) {
      disponibilites.forEach((date) => {
        filter = `${filter}&disponibilites=${date}`;
      });
    }
    const response = await etablissementService.getEtablissements({
      page: page,
      pageLimit: pageLimit,
      metierFilter: metierFilter,
      villeFilter: villeFilter,
      lat: lat,
      lon: lon,
      typeEtablissement: typeEtablissement,
      disponibilites: disponibilites,
    });
    const etablissement = response?.data?.etablissements as Etablissement[];
   

    const totalEtablissements = response?.data?.total as number;

    setIsLoading(false);

    return { etablissement, totalEtablissements, filter };
  }
  if (isLoading) return <Loader />;
  return (
    <article className={styles.etablissementSearchPage}>
      <header className={styles.etablissementSearchPage__header}>
        <h1>Trouver un établissement</h1>
      </header>
      <main className={styles.etablissementSearchPage__main}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <EtablissementSearchBar
            control={control}
            register={register}
            formErrors={formErrors}
            setError={setError}
            clearErrors={clearErrors}
            localisation={localisation}
            setLocalisation={setLocalisation}
          />
        </form>
        <EtablissementList
          setIsAddCollaborateurPopupOpen={setIsPopupOpen}
          etablissements={etablissements as Etablissement[]}
          ville={localisationFilterValue?.ville || ""}
        />
      </main>
      <footer className={styles.etablissementSearchPage__footer}>
        <PaginationFooter
          page={page}
          setPage={setPage}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
          totalSize={totalEtablissements}
        />
      </footer>

      {currentFlow === "inscription" && (
        <ConfirmeParticipationPopup
          isOpen={isPopupOpen}
          setIsOpen={setIsPopupOpen}
          type="addCollaborateur"
          cancelButtonText="Annuler"
          confirmButtonText="Continuer"
          onConfirm={() => {}}
          partcipation={selectedParticipation}
          navigateTo={`${onValidationLink}`}
        />
      )}
      {currentFlow === "modification" && (
        <ConfirmeParticipationPopup
          isOpen={isPopupOpen}
          setIsOpen={setIsPopupOpen}
          type="editCollaborateur"
          cancelButtonText="Annuler"
          confirmButtonText="Confirmation de la modification"
          onConfirm={() => {}}
          partcipation={selectedParticipation}
          navigateTo={`${onValidationLink}`}
        />
      )}
    </article>
  );
}

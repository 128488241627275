import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

interface NotificationState {
  showCancelInscriptionNotification: boolean;
  setShowCancelInscriptionNotification: (newState: boolean) => void;
}

export const useNotificationStore = create<NotificationState>()(
  devtools(
    persist(
      (set) => ({
        showCancelInscriptionNotification: false,
        setShowCancelInscriptionNotification: (newState) =>
          set((state) => ({ showCancelInscriptionNotification: newState })),
      }),
      {
        name: "notification-state-storage",
      }
    )
  )
);

import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import {
  Collaborateur,
  ICollaborateur,
} from "../../schemas/collaborateur/collaborateur.schema";

interface collaborateurState {
  etablissementSearchFilter: string;
  setEtablissementSearchFilter: (newState: string) => void;

  selectedCollaborateur: ICollaborateur  | null;
  setSeletecCollaborateur: (
    newCollaborateur: ICollaborateur  | null
  ) => void;

  
}

export const useCollaborteurStore = create<collaborateurState>()(
  devtools(
    persist(
      (set) => ({
        etablissementSearchFilter: "",
        setEtablissementSearchFilter: (newState) =>
          set((state) => ({ etablissementSearchFilter: newState })),
        selectedCollaborateur: null,
        setSeletecCollaborateur: (newState) =>
          set((state) => ({ selectedCollaborateur: newState })),
      }),
      {
        name: "collaborateur-state-storage",
      }
    )
  )
);

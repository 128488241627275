import axiosClient from "../../libs/axios";

async function sendEmail(mailingServiceRequestBody:{email: string, subject: string, body: string}) {
  return await axiosClient.post("/email", mailingServiceRequestBody);
}

const mailingServiceService={
    sendEmail
}

export default mailingServiceService;
import React from "react";

import styles from "./CustomeModal.module.scss";

type Props = {
  isOpen: boolean;
  setIsOpen:
    | React.Dispatch<React.SetStateAction<boolean>>
    | ((newState: boolean) => void);
  onClose: () => void;
  children: React.ReactNode;
};

export default function CustomeModal(props: Props) {
  const { isOpen, setIsOpen, onClose, children } = props;

  function onCloseHandler() {
    onClose();
    setIsOpen(false);
  }
  if (isOpen)
    return (
      <article className={styles.customeModal}>
        <section
          onClick={onCloseHandler}
          className={styles.customeModal__container}
        ></section>
        <section className={styles.customeModal__content}>
          {children}
        </section>
      </article>
    );
  else return null;
}

import { PublicClientApplication, AccountInfo } from "@azure/msal-browser";
import { AxiosResponse } from "axios";

export function getUserEmail(accounts: AccountInfo[]) {
  const userEmail = accounts[0]?.idTokenClaims?.email as string;

  return { userEmail };
}
export async function getToken(
  userEmail: string,
  msalInstance: PublicClientApplication,
  getAccessToken: (email: string) => Promise<AxiosResponse<any, any>>
) {
  const alertMessage =
    "Oups ! Vous n'avez pas les droits nécessaires pour accéder à la plateforme, merci d'envoyer un email à l'adresse vismavie@clariane.com afin que l'administrateur puisse débloquer rapidement la situation.";
  try {
    const tokenResponse = await getAccessToken(userEmail as string);
    const token: string = tokenResponse.data;
    if (!token) {
      alert(alertMessage);
      await msalInstance.logoutRedirect();
    }

    return token;
  } catch (error) {
    alert(alertMessage);
    await msalInstance.logoutRedirect();
  }
}

export function scrollToAnchor(anchor: HTMLElement | null) {
  if (anchor) {
    anchor.scrollIntoView({ behavior: "smooth" });
  }
}

export function areDatesEqual(dateA: Date, dateB: Date) {
  return new Date(dateA).getTime() === new Date(dateB).getTime();
}

export function formatDate(
  date: Date,
  options?: {
    hasFullWeekDay?: boolean;
    hasDayName?: boolean;
    hasYear?: boolean;
  }
) {
  const formatingOptions: Intl.DateTimeFormatOptions | undefined = {
    month: "long",
  };

  if (options?.hasFullWeekDay) {
    formatingOptions.weekday = "long";
  }
  if (options?.hasDayName) {
    formatingOptions.day = "numeric";
  }
  if (options?.hasYear) {
    formatingOptions.year = "numeric";
  }

  return new Date(date).toLocaleDateString("fr-FR", formatingOptions);
}

export function getUserInfo(accounts: AccountInfo[]) {
  const userEmail = accounts[0].idTokenClaims?.email as string;
  const userName = accounts[0].name || "prenom nom";
  const names = userName.split(" ");
  const prenom = names[0];
  const nom = names[1];

  return { nom, prenom, userEmail };
}

export function isEmpty(value: unknown): boolean {
  if (Array.isArray(value)) {
    return value.length === 0;
  }

  if (typeof value === "object" && value !== null) {
    return Object.keys(value).length === 0;
  }

  return false;
}


export function formatPhoneNumber(phone : string | undefined) {
  return phone ? `+33 ${phone.replace(/(\d)(?=(\d{2})+$)/g, '$1 ')}` : '-'
}

export function formatPhoneWithoutPrefix(phone:string|undefined){
  return phone ? `${phone.replace(/(\d)(?=(\d{2})+$)/g, '$1 ')}` : ''

}

export function removeQuotesFromString(inputString:string) {
  return inputString.replace(/"/g, '');
}
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import styles from "./Login.module.scss";
import { msalInstance } from "../../configurations/auth/msal.auth.configuration";
import { useState } from "react";
import Loader from "../../components/global/Loader/Loader";
import { PublicClientApplication } from "@azure/msal-browser";
import {
  LoginValidationSchema,
  LoginValidationType,
} from "../../schemas/login/login.schema";

export default function Login() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //* react-hook-form
  const { handleSubmit } = useForm<LoginValidationType>({
    resolver: yupResolver(LoginValidationSchema),
  });

  async function onLogin() {
    await msalInstance.loginRedirect();
  }

  function loginWithRedirectAndLoading(msalInstance: PublicClientApplication) {
    return new Promise(async (resolve, reject) => {
      setIsLoading(true);
      try {
         await msalInstance.loginRedirect();

        setIsLoading(false);
        resolve(null);
      } catch (error) {
        setIsLoading(false);
        reject(error);
      }
    });
  }

  return (
    <article className={styles.login}>
      {isLoading && <Loader />}
      <form
        onSubmit={handleSubmit(onLogin)}
        className={styles.login__loginWindow}
      >
        <header className={styles.login__loginWindow__header}>
          <h1>Connexion</h1>
        </header>

        <footer className={styles.login__loginWindow__footer}>
          <button
            type="submit"
            onClick={async () => {
              await loginWithRedirectAndLoading(msalInstance);
            }}
          >
            Se connecter
          </button>
        </footer>
      </form>
    </article>
  );
}

import { pencilIcon } from "../../assets/svgs/icons/icons.svg";
import { useCollaborteurStore } from "../../stores/collaborateur/collaborateur.store";
import { formatPhoneNumber } from "../../utils";
import InformationSection from "../InformationSection/InformationSection";
import Button from "../global/Button/Button";

import styles from "./InscriptionRecapInfoPersonnelles.module.scss";

type Props = {
  setIsOpenModificationPopup?: React.Dispatch<React.SetStateAction<boolean>>;
  hasModifierButton: boolean;
};
export default function InscriptionRecapInfoPersonnelles(props: Props) {
  const { setIsOpenModificationPopup, hasModifierButton } = props;
  const { selectedCollaborateur } = useCollaborteurStore();

  function onClickModifier() {
    setIsOpenModificationPopup && setIsOpenModificationPopup(true);
  }
  return (
    <article
      className={styles.inscriptionRecap}
      is-recap-page={hasModifierButton ? "yes" : "no"}
    >
      <article className={styles.inscriptionRecap__InfoPersonnelles}>
        <header className={styles.inscriptionRecap__InfoPersonnelles__header}>
          <h2>Informations personnelles</h2>
          {hasModifierButton && (
            <Button
              text="Modifier"
              buttonBackGroundColor="white"
              textColor="dark"
              icon={pencilIcon}
              onClick={onClickModifier}
            />
          )}
        </header>
        <main className={styles.inscriptionRecap__InfoPersonnelles__main}>
          <section
            className={styles.inscriptionRecap__InfoPersonnelles__civilite}
          >
            <InformationSection
              label="Civilité"
              text={selectedCollaborateur?.civilite || "-"}
            />
          </section>

          <section
            className={styles.inscriptionRecap__InfoPersonnelles__mainInfos}
          >
            <section>
              <InformationSection
                label="NOM"
                text={selectedCollaborateur?.nom || "-"}
              />
              <InformationSection
                label="Email"
                text={selectedCollaborateur?.email || "-"}
              />
              <InformationSection
                label="Fonction"
                text={selectedCollaborateur?.fonction || "-"}
              />
            </section>
            <section>
              <InformationSection
                label="Prénom"
                text={selectedCollaborateur?.prenom || "-"}
              />
              <InformationSection
                label="Téléphone"
                text={formatPhoneNumber(selectedCollaborateur?.phone)}
              />
              <InformationSection
                label="Service"
                text={selectedCollaborateur?.direction || "-"}
              />
            </section>
          </section>
        </main>
      </article>
      <article className={styles.inscriptionRecap__droitImage}>
        <header className={styles.inscriptionRecap__droitImage__header}>
          <h2>Droit à l'image</h2>
        </header>
        <main className={styles.inscriptionRecap__droitImage__main}>
          <section className={styles.inscriptionRecap__droitImage__main__text}>
            <p>
              Des photos pourront être prises dans le cadre de ce Vis ma Vie.
            </p>
            <p>
              Acceptez-vous que votre image soit diffusée dans le cadre de
              communications internes et externes ?
            </p>
          </section>
          <section
            className={styles.inscriptionRecap__droitImage__main__radioButtons}
          >
            <label>
              <input
                id="droitImage_recap_radio_button_true"
                type="radio"
                name="droitImage_recap_radio_button_true"
                value="true"
                readOnly
                checked={selectedCollaborateur?.hasDroitImage}
              />
              Oui
            </label>
            <label>
              <input
                id="droitImage_inscirption_radio_button_false"
                type="radio"
                name="droitImage_recap_radio_button_true"
                value="false"
                readOnly
                checked={!selectedCollaborateur?.hasDroitImage}
              />
              Non
            </label>
          </section>
        </main>
      </article>
    </article>
  );
}

import axiosClient from "../libs/axios";
import { ICollaborateur } from "../schemas/collaborateur/collaborateur.schema";


async function getCollaborateurByEmail(email: string) {
  return axiosClient.get<ICollaborateur>(`/users/collaborateur/${email}`);
}

const usersServices = {
  getCollaborateurByEmail,
};

export default usersServices;

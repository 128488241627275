import styles from "./InformationSection.module.scss";

type Props = {
  label: string;
  text: string;
  maxSectionWidth?: string;
};
export default function InformationSection(props: Props) {
  const { label, text,maxSectionWidth } = props;
  return (
    <article className={styles.informationSection}>
      <label htmlFor={label + text}>{label}</label>
      <section
      style={{maxWidth:maxSectionWidth}}
      >
        <p id={label + text}> {text}</p>
      </section>
    </article>
  );
}

import { Fragment } from "react";
import EtablissementCard from "../EtablissementCard/EtablissementCard";
import { Etablissement } from "../../schemas/etablissement/etablissemen.schema";

import styles from "./EtablissementList.module.scss";
import PaginationFooter from "../PaginationFooter/PaginationFooter";
import { noResults } from "../../assets/svgs/pictures/no-result.svg";
type Props = {
  etablissements: Etablissement[];
  setIsAddCollaborateurPopupOpen:React.Dispatch<React.SetStateAction<boolean>>
  ville : string,
};
export default function EtablissementList(props: Props) {
  const { etablissements,setIsAddCollaborateurPopupOpen,ville } = props;
  return (
    <article className={styles.etablissementList}>
      <header className={styles.etablissementList__header}>
        <p>
          Sélectionner une journée Vis ma vie de 9h30 à 17h30 dans un
          établissement {ville && `au plus proche de ${ville}`}.
        </p>
        <p>
          <strong>Attention : </strong> aucun frais de déplacement ne pourra être
          pris en compte par votre direction.
        </p>
      </header>
      <main className={styles.etablissementList__main}>
        {etablissements.length !== 0 ? (
          etablissements.map((etablissement: Etablissement, index: number) => (
            <Fragment key={"etablissementList_" + index + etablissement.name}>
              <EtablissementCard 
              
              setIsAddCollaborateurPopupOpen={setIsAddCollaborateurPopupOpen}
              etablissement={etablissement} />
            </Fragment>
          ))
        ) : (
          <>
            <h1>Aucun résultat trouvé ...</h1>
            {noResults}
          </>
        )}
      </main>
    </article>
  );
}

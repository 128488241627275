import styles from "./DisponibiliteButton.module.scss";

type Props = {
  label: string;
  value: string;
  disponibilitesFilter: string[];
  weeklist: number;
  setDisponibilitesFilter: React.Dispatch<React.SetStateAction<string[]>>;
};
export default function DisponibiliteButton(props: Props) {
  const { label, value, disponibilitesFilter, setDisponibilitesFilter, weeklist } = props;
  function handleClick() {
    if (disponibilitesFilter.includes(value)) {
      setDisponibilitesFilter(
        disponibilitesFilter.filter((disponibilite) => disponibilite !== value)
      );
    } else {
      setDisponibilitesFilter([...disponibilitesFilter, value]);
    }
  }
  return (
    <button
      type="button"
      className={styles.disponibiliteButton}
      is-selected={
        disponibilitesFilter.includes(value) ? "selected" : "unselected"
      }
      onClick={handleClick}
    >
      {label}
    </button>
  );
}

import { Link, useLocation, useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";

import { korianLogo } from "../../../assets/svgs/logos/logo_2.svg";
import { msalInstance } from "../../../configurations/auth/msal.auth.configuration";

import styles from "./Header.module.scss";

const Header = () => {
  const { accounts } = useMsal();

  const navigate = useNavigate();
  const { pathname } = useLocation()
  const handleBackToHome = () => {
    navigate("/");
    return;
  };
  return (
    <header className={styles.headerTopBar}>
      <section className={styles.headerTopbar__left}>
        <h1 onClick={handleBackToHome}>{korianLogo}</h1>
      </section>

      {
        pathname !== "/cloture" &&  <section className={styles.headerTopbar__right}>
        <Link className={styles.headerTopbar__right__faqButton} to="/FAQ">
          FAQ
        </Link>

        <section className={styles.headerTopbar__right__deconnexion}>
          <a
            href="/"
            onClick={async () => {
              localStorage.setItem("accessToken", "null");
              await msalInstance.logoutRedirect();
            }}
          >
            Déconnexion
          </a>
        </section>
      </section>
      }

     
    </header>
  );
};

export default Header;

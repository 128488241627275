import { useQuery } from "@tanstack/react-query";
import metiersServices from "../../../services/dropdowns/metiers.servcie";

export function useGetMetiers() {
  return useQuery({
    queryKey: ["dropDowns-metiers"],
    queryFn: metiersServices.getMetiers,
    refetchInterval: 0,
    refetchOnWindowFocus: false,
  });
}

import { Control, FieldValues, Path, useController } from "react-hook-form";

import styles from "./DropdownSection.module.scss";
import { AccountIcon } from "../../../assets/svgs/Account";

export type DropdownTypeInBackend = {
  _id: string;
  label: string;
};
type Props<FormSchemaType extends FieldValues> = {
  options: string[] | DropdownTypeInBackend[];
  label: string;
  placeholder: string;
  inputId: string;
  name: Path<FormSchemaType>;
  control: Control<any>;
  hasIcon: boolean;
  isUsingInHomePage?: boolean;
  areOptionsLoading?: boolean;
  hideIcon?: boolean;
};

export default function DropdownSection<FormSchemaType extends FieldValues>(
  props: Props<FormSchemaType>
) {
  const {
    options,
    label,
    placeholder,
    inputId,
    name,
    control,
    areOptionsLoading,
    isUsingInHomePage,
    hasIcon,
  } = props;
  const { field, formState } = useController<FormSchemaType>({ name, control });
  const formErrors = formState.errors;
  const errorName = formErrors?.[name];
  return (
    <section className={styles.dropdownSection}>
      <label className={styles.dropdownSection__titleLabel} htmlFor={inputId}>
        {label}
      </label>
      {hasIcon && <div className={styles.icon_wrapper}>{AccountIcon}</div>}
      <select
        {...field}
        id={inputId}
        defaultValue={placeholder || "-"}
        style={{
          width:"370px",
          border: isUsingInHomePage ? "1px solid #003E5C" : "none",
          padding: hasIcon ? "0px 16px 0px 46px" : "0px 16px 0px 16px",
        }}
        has-error={errorName ? "yes" : "no"}
      >
        {!areOptionsLoading ? (
          <>
            <option value={placeholder || "-"} disabled hidden>
              {placeholder || "-"}
            </option>

            {options?.map((option, index) => {
              if (typeof option === "string")
                return (
                  <option key={`dropdownSection_${index}`} value={option}>
                    {option}
                  </option>
                );
              return (
                // Here the type of option is DropdownTypeInBackend
                <option key={`dropdownSection_${index}`} value={option?.label}>
                  {option?.label}
                </option>
              );
            })}
          </>
        ) : (
          <option value={""}>Chargement...</option>
        )}
      </select>

      <label className={styles.dropdownSection__errorLabel} htmlFor={inputId}>
        {formErrors?.[name]?.message?.toString()}
      </label>
    </section>
  );
}

import React from 'react'
import { Progress } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { leftArrow } from '../../assets/svgs/leftArrow.svg';

import styles from "./ResearcheLoader.module.scss";


import Research10 from "../../assets/png/research_loader/research_10.png"
import Research20 from "../../assets/png/research_loader/research_20.png"
import Research40 from "../../assets/png/research_loader/research_30.png"
import Research50 from "../../assets/png/research_loader/research_40.png"
import Research70 from "../../assets/png/research_loader/research_70.png"
import Research80 from "../../assets/png/research_loader/research_80.png"
import NORESULT from "../../assets/png/research_loader/no_result.png"
import NODISPO from "../../assets/png/research_loader/no_dispo.png"



const loaderImgaesSrc: string[] = [
  Research10,
  Research20,
  Research40,
  Research50,
  Research70,
  Research80,
  NORESULT,
  NODISPO
]


const ResearchText : string[] = [
  "Recherche d’établissements ...",
  "Aucun résultat trouvé ...",
  "Désolé mais le créneau que vous venez de sélectionner n’est plus disponible,\n veuillez sélectionner un nouveau créneau. "
]


const ResearchLoader = () => {

  const navigate = useNavigate()


  return (
    <article className={styles.research_loader_container}>
      <button 
        onClick={() => {
          navigate(-1)
        }} 
      >
        {leftArrow}
        Retour
      </button>
      <div >
        <h1>{ResearchText[0]}</h1>
        
          <img src={loaderImgaesSrc[0]} alt="loader" />
          <p>14%</p>
          <Progress className={styles.loader} color="#003E5C" value={14} />
        
      </div>
      
    </article>
  )
}

export default ResearchLoader
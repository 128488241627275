import { Fragment, useState } from "react";
import CalenderDropdownPopup from "../../CalenderDropdownPopup/CalenderDropdownPopup";
import { UseFormRegister } from "react-hook-form";
import styles from "./CalendarDropdownFilter.scss.module.scss";
import { useEtablissementStore } from "../../../stores/etablissement/etablissement.store";
import { Calender } from "../../../assets/svgs/Calendar";

type Props = {
  disponibilitesFilter: string[];
  setDisponibilitesFilter: React.Dispatch<React.SetStateAction<string[]>>;
  register: UseFormRegister<any>;
  isUsingInHomePage?: boolean;
};
export default function CalendarDropdownFilter(props: Props) {
  const { disponibilitesFilter, setDisponibilitesFilter, isUsingInHomePage } = props;
  const [isPopupVisible, setIsPopupVisible] = useState<boolean>(false);
  const {selectedDisponibilites} = useEtablissementStore()

  const selectedDisponibilitesTString = (dispo: string[]): string => {
    let str = ""
    if(!dispo?.length) return str;

    dispo.forEach((disponibilite, index) => {
      str= str + new Date(disponibilite).toLocaleString("fr-FR", {
                      weekday: "long",
                      day: "numeric",
                      month: "long",
                    }).toString() + ", "
    })

    return str
  }

  return (
    <article className={styles.calendarDropdownFilter}>
      <button
        type="button"
        className={styles.calendarDropdownFilter__button}
        onClick={() => setIsPopupVisible(true)}
        style={{
          border: isUsingInHomePage ? "1px solid #003E5C" : "none",
          boxShadow: isUsingInHomePage ? "inset -1px 1px 5px rgba(0, 0, 0, 0.2)" : "none",
          marginTop: isUsingInHomePage ? "8px" : "0px",
        }}
      >
        <div>
        {Calender}
        </div>
        <section style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          width: "100%",
        }}>
          {/* {selectedDisponibilites.length>0
            ? selectedDisponibilites.map((disponibilite, index) => {
                return (
                  <Fragment key={"display_date_in_filter_inputton" + index}>
                  <p>
                    {new Date(disponibilite).toLocaleString("fr-FR", {
                      weekday: "long",
                      day: "numeric",
                      month: "long",
                    })},{"  "}
                  </p>
                  <input  {...props.register(`disponibilitesFilter.${index}`)} type="hidden" value={disponibilite}  />
                  </Fragment>
                );
              })
            : 
            "Sélectionner vos disponibilités"} */}

            {selectedDisponibilites.length>0
            ? 
            <p style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "240px",
              }}
        >
              {selectedDisponibilitesTString(selectedDisponibilites)} 
             </p>
            
            : 
            "Sélectionner vos disponibilités"}
        </section>
      </button>
      {isPopupVisible && (
        <CalenderDropdownPopup
          disponibilitesFilter={selectedDisponibilites}
          setDisponibilitesFilter={setDisponibilitesFilter}
          isPopupVisible={isPopupVisible}
          setIsPopupVisible={setIsPopupVisible}
        />
      )}
    </article>
  );
}
